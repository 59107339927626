import { Box, List, ListItem, ListItemText } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { beforeYouStartData } from '../../../data/states/BeforeYouStartData';
import { DEAL, UNDERLYING_ASSET } from '../../../data/states/States';
import { setAccountClick } from '../../../features/account/accountClickSlice';
import { setAccount } from '../../../features/account/accountSlice';
import { setActiveDepositState } from '../../../features/activeState/activeDepositStateSlice';
import { setActiveWithdrawState } from '../../../features/activeState/activeWithdrawStateSlice';
import { FilledButton } from '../../buttons/FilledButton';
import { OutlinedCard } from '../../cards/OutlinedCard';
import { DescriptionButton } from '../../DescriptionButton';
import Web3 from 'web3';

function renderList(list: Array<string>) {
  return list.map((text, index) => 
    <ListItem key={index} component="div" disablePadding sx={[
      {
        color: '#000000'
      }
    ]}>
      <ListItemText primary={`${index + 1}. ${text}`} />
    </ListItem>
  )
}

export function BeforeYouStart() {
  const dispatch = useDispatch();

  // const changeNetwork = async () => {
  //   if ((window as any).ethereum) {
  //     try {
  //       await (window as any).ethereum
  //       .request({method: 'wallet_switchEthereumChain', params: [{ chainId: Web3.utils.toHex(1) }],})
  //     }
  //     catch (error) {
  //       console.log(error);
  //     }
  //   }
  // }
  
  const connectHandleClick = async () => {

    if ((window as any).ethereum) {
      if ((window as any).ethereum.networkVersion !== 1) {
        await (window as any).ethereum
        .request({method: 'wallet_switchEthereumChain', params: [{ chainId: Web3.utils.toHex(1) }],})
        .then((res: any) => {
          console.log(res);
          if (res == null) {
            (window as any).ethereum
            .request({method: 'eth_requestAccounts'})
            .then((res: string[]) => {
              dispatch(setAccountClick(true));
              dispatch(setAccount(res[0]));
              dispatch(setActiveDepositState(UNDERLYING_ASSET));
              dispatch(setActiveWithdrawState(DEAL));
            });
          }
        })
      }
      else {
        await (window as any).ethereum
        .request({method: 'eth_requestAccounts'})
        .then((res: string[]) => {
          dispatch(setAccountClick(true));
          dispatch(setAccount(res[0]));
          dispatch(setActiveDepositState(UNDERLYING_ASSET));
          dispatch(setActiveWithdrawState(DEAL));
        });
      }
    } else {
      alert("Please, install Metamask extension");
    }
  }

  return (
    <OutlinedCard avatar={beforeYouStartData.avatar} title={beforeYouStartData.title} >
      <List sx={{
        padding: 0,
      }}>
        {renderList(beforeYouStartData.list)}
      </List>
      <Box mt={28.5}>
        <FilledButton 
          startIcon={beforeYouStartData.buttonStartIcon} 
          text={beforeYouStartData.buttonText} 
          onClick={connectHandleClick}
          />
        <Box mt={0.5} ml={0.25}>
          <DescriptionButton descriptionText={beforeYouStartData.buttonDesc} />
        </Box>
      </Box>
    </OutlinedCard>
  );
}
