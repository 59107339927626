import { configureStore } from '@reduxjs/toolkit';
import dropdownMenuValueReducer from '../features/selects/dropdownMenuValueSlice';
import dropdownMenuWithdrawValueReducer from '../features/selects/dropdownMenuWithdrawValue';
import textFieldValueReducer from '../features/textfields/textFieldValueSlice';
import textFieldBlurReducer from '../features/textfields/textFieldBlurSlice';
import approvalReducer from '../features/approve/approvalSlice';
import approveClickReducer from '../features/approve/approveClickSlice';
import accountReducer from '../features/account/accountSlice';
import accountClickReducer from '../features/account/accountClickSlice';
import activeDepositStateReducer from '../features/activeState/activeDepositStateSlice';
import walletBalanceReducer from '../features/walletBalanceSlice';
import checkedMaxWalletBalanceReducer from '../features/checkedMaxWalletBalance/checkedMaxWalletBalanceSlice';
import switchFeesOptimizationReducer from '../features/switchFeesOptimizationSlice';
import feeValueReducer from '../features/feeValueSlice';
import feeValueETHReducer from '../features/feeValueETHSlice';
import linkEtherscanDepositReducer from '../features/etherscanLinks/linkEtherscanDepositSlice';
import linkEtherscanWithdrawnReducer from '../features/etherscanLinks/linkEtherscanWithdrawnSlice';
import approvalUSDTReducer from '../features/approve/approvalUSDTSlice';
import approvalUSDCReducer from '../features/approve/approvalUSDCSlice';
import approvalDAIReducer from '../features/approve/approvalDAISlice';
import activeWithdrawStateReducer from '../features/activeState/activeWithdrawStateSlice';
import estimatedIncomeReducer from '../features/estimatedIncomeSlice';
import receiveSumReducer from '../features/receiveSumSlice';
import textFieldDepositErrorReducer from '../features/textfields/textFieldDepositErrorSlice';
import apyUsdtReducer from '../features/apyUsdtSlice';
import approveFeeReducer from '../features/approve/approveFeeSlice';
import checkedMaxWalletBalanceClickReducer from '../features/checkedMaxWalletBalance/checkedMaxWalletBalanceClickSlice';
import showLoaderReducer from '../features/showLoaderSlice';
import progressDepositListReducer from '../features/progress/progressDepositListSlice';
import progressWithdrawListReducer from '../features/progress/progressWithdrawListSlice';
import isApproveReducer from '../features/approve/isApproveSlice';
import isFirstStartReducer from '../features/isFirstStartSlice';
import estimatedFeeErrorReducer from '../features/estimatedFeeErrorSlice';

const store = configureStore({
  reducer: {
    dropdownMenuValue: dropdownMenuValueReducer,
    dropdownMenuWithdrawValue: dropdownMenuWithdrawValueReducer,

    textFieldValue: textFieldValueReducer,
    textFieldDepositError: textFieldDepositErrorReducer,
    textFieldBlur: textFieldBlurReducer,

    approval: approvalReducer,
    isApprove: isApproveReducer,
    approveClick: approveClickReducer, 
    approvalUSDT: approvalUSDTReducer,
    approvalUSDC: approvalUSDCReducer,
    approvalDAI: approvalDAIReducer,

    account: accountReducer,
    accountClick: accountClickReducer, 

    activeDepositState: activeDepositStateReducer,
    progressDepositList: progressDepositListReducer, 
    
    walletBalance: walletBalanceReducer,

    apyUsdt: apyUsdtReducer,

    checkedMaxWalletBalance: checkedMaxWalletBalanceReducer,
    checkedMaxWalletBalanceClick: checkedMaxWalletBalanceClickReducer,

    switchFeesOptimization: switchFeesOptimizationReducer,

    feeValue: feeValueReducer,
    feeValueETH: feeValueETHReducer,
    approveFee: approveFeeReducer,
    estimatedFeeError: estimatedFeeErrorReducer,

    linkEtherscanDeposit: linkEtherscanDepositReducer,
    linkEtherscanWithdrawn: linkEtherscanWithdrawnReducer,

    activeWithdrawState: activeWithdrawStateReducer,
    progressWithdrawList: progressWithdrawListReducer,

    estimatedIncome: estimatedIncomeReducer,
    receiveSum: receiveSumReducer,

    showLoader: showLoaderReducer,

    isFirstStart: isFirstStartReducer,
    
  }
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;