import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const linkEtherscanDepositSlice = createSlice({
  name: 'linkEtherscanDeposit',
  initialState: {
    value: "",
  },
  reducers: {
    setlinkEtherscanDeposit: (state: any, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { setlinkEtherscanDeposit } = linkEtherscanDepositSlice.actions;

export default linkEtherscanDepositSlice.reducer;