import { Header } from "./components/Header";
import { Container, ThemeProvider, Box, Grid } from "@mui/material";
import { theme } from "./theme";
import React from "react";
import { CustomTabs } from "./components/CustomTabs";
import { tabsData } from "./data/TabsData";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container maxWidth="lg" sx={{
        marginTop: "48px",
        "@media (min-width: 1200px)": {
          maxWidth: '1128px'
        }
      }}>
        <Box>
          <CustomTabs tabList={tabsData.tabList} panelList={tabsData.panelList} />
        </Box>

      </Container>
    </ThemeProvider>
  );
}

export default App;
