import { createTheme } from "@mui/material";
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3',
      light: '#E3F2FD',
      dark: '#1045A1',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#1F4CF0',
      light: '#E9EAFE',
      dark: '#000FBC',
      contrastText: '#ffffff',
    },
    error: {
      main: '#DC362E',
      light: '#F9DEDC',
      dark: '#410E0B',
      contrastText: '#ffffff',
    },
    // neutral: {
    //   main: '#ffffff',
    //   light: '#9E9EA2',
    //   dark: '#9E9EA2',
    //   contrastText: '#616165',
    // },
    success: {
      main: '#00D5BE',
      light: '#D9FCFB',
      dark: '#008267',
      contrastText: '#ffffff',
    }
  },
  typography: {
    fontFamily: 'Roboto, Arial',
    button: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      textTransform: 'none',
    }
  },
});