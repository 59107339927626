import React from "react";

export function WBTCIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_302_1507)">
    <path d="M19.5695 5.03679L18.9105 5.69577C20.4829 7.41533 21.3549 9.6611 21.3549 11.9912C21.3549 14.3213 20.4829 16.5671 18.9105 18.2867L19.5695 18.9456C21.3182 17.0488 22.289 14.5634 22.289 11.9835C22.289 9.40363 21.3182 6.91822 19.5695 5.02142V5.03679Z" fill="#5A5564"/>
    <path d="M5.71114 5.09391C7.43071 3.52148 9.67647 2.64948 12.0066 2.64948C14.3367 2.64948 16.5825 3.52148 18.302 5.09391L18.961 4.43493C17.0642 2.68622 14.5788 1.71535 11.9989 1.71535C9.41901 1.71535 6.93359 2.68622 5.03679 4.43493L5.71114 5.09391Z" fill="#5A5564"/>
    <path d="M5.0939 18.2932C3.52333 16.5741 2.65249 14.3297 2.65249 12.0011C2.65249 9.67252 3.52333 7.42813 5.0939 5.70895L4.43492 5.04997C2.68621 6.94677 1.71535 9.43219 1.71535 12.0121C1.71535 14.592 2.68621 17.0774 4.43492 18.9742L5.0939 18.2932Z" fill="#5A5564"/>
    <path d="M18.2932 18.9017C16.5737 20.4741 14.3279 21.3461 11.9978 21.3461C9.66769 21.3461 7.42192 20.4741 5.70236 18.9017L5.04338 19.5607C6.94018 21.3094 9.4256 22.2803 12.0055 22.2803C14.5854 22.2803 17.0708 21.3094 18.9676 19.5607L18.2932 18.9017Z" fill="#5A5564"/>
    <path d="M16.1604 9.80121C16.0286 8.42614 14.8424 7.96486 13.3421 7.82427V5.93081H12.1823V7.78913C11.877 7.78913 11.5651 7.78913 11.2554 7.78913V5.93081H10.1043V7.83745H7.75179V9.07853C7.75179 9.07853 8.60846 9.06315 8.59528 9.07853C8.74974 9.06154 8.90477 9.10517 9.0277 9.20023C9.15063 9.2953 9.23185 9.43437 9.25426 9.58814V14.8073C9.25096 14.8615 9.23681 14.9145 9.21267 14.9632C9.18852 15.0118 9.15486 15.0552 9.11367 15.0906C9.07326 15.1267 9.02597 15.1543 8.97463 15.1717C8.92329 15.189 8.86897 15.1958 8.81494 15.1917C8.83031 15.2048 7.97144 15.1917 7.97144 15.1917L7.75179 16.5777H10.0824V18.5151H11.2422V16.6063H12.1691V18.5063H13.3311V16.5909C15.2905 16.4723 16.6568 15.989 16.8281 14.1549C16.9665 12.6788 16.2724 12.0198 15.1631 11.754C15.8375 11.4223 16.2548 10.8073 16.1604 9.80121ZM14.5349 13.9264C14.5349 15.3674 12.0659 15.2026 11.2795 15.2026V12.6458C12.0659 12.648 14.5349 12.4217 14.5349 13.9264ZM13.9967 10.324C13.9967 11.642 11.9363 11.4816 11.2817 11.4816V9.15761C11.9363 9.15761 13.9967 8.95113 13.9967 10.324Z" fill="#F09242"/>
    <path d="M11.9978 24C9.6246 23.9995 7.30482 23.2954 5.33177 21.9767C3.35872 20.658 1.82099 18.7838 0.913008 16.5912C0.0050285 14.3985 -0.232426 11.9859 0.230666 9.65836C0.693759 7.33079 1.8366 5.1928 3.5147 3.5147C5.1928 1.8366 7.33079 0.693759 9.65836 0.230666C11.9859 -0.232426 14.3985 0.0050285 16.5912 0.913008C18.7838 1.82099 20.658 3.35872 21.9767 5.33177C23.2954 7.30482 23.9995 9.6246 24 11.9978C24.0003 13.574 23.69 15.1349 23.087 16.5912C22.4839 18.0475 21.5998 19.3707 20.4853 20.4853C19.3707 21.5998 18.0475 22.4839 16.5912 23.087C15.1349 23.69 13.574 24.0003 11.9978 24V24ZM11.9978 0.935732C9.81135 0.93747 7.6745 1.58736 5.85733 2.80325C4.04016 4.01914 2.62424 5.74646 1.78853 7.76688C0.952816 9.7873 0.734835 12.0101 1.16213 14.1544C1.58943 16.2987 2.64283 18.2682 4.18918 19.8139C5.73553 21.3596 7.70542 22.4122 9.84987 22.8387C11.9943 23.2651 14.2171 23.0463 16.2371 22.2098C18.2572 21.3732 19.984 19.9566 21.1992 18.139C22.4143 16.3213 23.0634 14.1842 23.0642 11.9978C23.0648 10.5446 22.7789 9.10548 22.2229 7.76282C21.667 6.42016 20.8518 5.20027 19.824 4.17289C18.7962 3.14551 17.576 2.3308 16.2331 1.77534C14.8902 1.21989 13.451 0.934577 11.9978 0.935732V0.935732Z" fill="#282138"/>
    </g>
    <defs>
    <clipPath id="clip0_302_1507">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
}