import { Box, FormControl, FormHelperText, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { Coin } from '../../../data/Coins';
import { setCheckedMaxWalletBalanceClick } from '../../../features/checkedMaxWalletBalance/checkedMaxWalletBalanceClickSlice';
import { setCheckedMaxWalletBalance } from '../../../features/checkedMaxWalletBalance/checkedMaxWalletBalanceSlice';
import { setTextFieldBlur } from '../../../features/textfields/textFieldBlurSlice';
import { setTextFieldDepositError } from '../../../features/textfields/textFieldDepositErrorSlice';
import { setTextFieldValue } from '../../../features/textfields/textFieldValueSlice';
import { CancelIcon, ErrorCircleFilledIcon } from '../../Icons';

export interface ITextFieldProps {
  id: string;
  startIcon?: React.ReactNode;
  helperErrorText?: string;
  helperText: string;
}

export function OutlinedTextFieldNumber({ 
  id,
  helperText,
}: ITextFieldProps) {

  const textFieldValue: string = useSelector((state: RootState) => state.textFieldValue.value);
  const [textFieldValueRepeat, setTextFieldValueRepeat] = React.useState<string>("");
  const dropdownMenuValue: Coin = useSelector((state: RootState) => state.dropdownMenuValue);
  const walletBalance: string = useSelector((state: RootState) => state.walletBalance.value);
  const checkedMaxWalletBalance = useSelector((state: RootState) => state.checkedMaxWalletBalance.value);
  const checkedMaxWalletBalanceClick = useSelector((state: RootState) => state.checkedMaxWalletBalanceClick.value);
  const textFieldDepositError = useSelector((state: RootState) => state.textFieldDepositError.value);
  const textFieldBlur = useSelector((state: RootState) => state.textFieldBlur.value);

  const [errorMsg, setErrorMsg] = React.useState('Please enter deposit amount');
  const [isChangingText, setIsChangingText] = React.useState(false);

  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    dispatch(setTextFieldValue(value));
    setTextFieldValueRepeat(value);
    setIsChangingText(true);

    if (parseFloat(value) === parseFloat(walletBalance)) {
      dispatch(setCheckedMaxWalletBalance(true));
    } else {
      dispatch(setCheckedMaxWalletBalance(false));
    }
    dispatch(setCheckedMaxWalletBalanceClick(false));
  }

  const handleFocus = () => dispatch(setTextFieldBlur(true));
  const handleBlur = () => {
    if (textFieldValue.length !== 0) {
      dispatch(setTextFieldBlur(true));
    } else {
      dispatch(setTextFieldBlur(false));
    }
  }

  const handleDelete = () => {
    const value: string = '';
    dispatch(setTextFieldValue(value));
    setTextFieldValueRepeat(value);
    dispatch(setTextFieldBlur(true));
    dispatch(setTextFieldDepositError(true));
    if (checkedMaxWalletBalance) {
      dispatch(setCheckedMaxWalletBalance(false));
    }
    dispatch(setCheckedMaxWalletBalanceClick(false));
  }

  const handleError = () => {
    const nullValue = textFieldValue.length === 0;
    const count = parseFloat(textFieldValue ? textFieldValue : '1');
    const walletBallanceValidation = count > (parseFloat(walletBalance));

    const textFieldValidation = count <= 0;

    if (walletBallanceValidation || textFieldValidation
      || (nullValue && isChangingText)
      ) {
      dispatch(setTextFieldDepositError((true)));
    } else {
      dispatch(setTextFieldDepositError((false)));
    }

    if (walletBallanceValidation) {
      setErrorMsg('The amount exceeds your wallet balance');
    } else if (textFieldValidation) {
      setErrorMsg('The amount can\'t be negative or equal to zero');
    } else if (textFieldValue.length === 0 && isChangingText) {
      setErrorMsg('Please enter deposit amount');
    }

    if (parseFloat(walletBalance) === 0) {
      setErrorMsg('Please top up your wallet balance');
    }
  }

  const setMaxWalletBalance = () => {
    if (checkedMaxWalletBalance) {
      dispatch(setTextFieldBlur(true));
      dispatch(setTextFieldValue(walletBalance.toString()));
    } else if (checkedMaxWalletBalanceClick) {
      dispatch(setTextFieldBlur(true));
      dispatch(setTextFieldValue(textFieldValueRepeat));
    }
  }
  
  React.useMemo(handleError, [textFieldValue, walletBalance]);
  React.useMemo(setMaxWalletBalance, [checkedMaxWalletBalance]);

  return (
    <FormControl error={textFieldDepositError} variant="outlined" sx={[
      { 
        width: '100%',
      },
      {
        '&:hover': {
          borderColor: '#616165',
        },
      },
      {
        '&:focus': {
          border: '2px solid #2196F3',
        },
      },
      ]}>
      <InputLabel htmlFor={id} sx={
        { 
          color: '#616165',
          '&.Mui-focused': {
            backgroundColor: '#F5F5F9',
          },
        }
        }>{helperText}</InputLabel>
      <OutlinedInput
        id={id}
        type='number'
        label={helperText}
        value={textFieldValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete='off'
        startAdornment={
          textFieldBlur ?
            <InputAdornment position="start">
              <Icon>
                {dropdownMenuValue.icon}
              </Icon>
              <Box ml={1.5} color='#616165'>
                {dropdownMenuValue.name}
              </Box>
            </InputAdornment>
          : null
        }
        endAdornment={
          textFieldBlur ?
          <InputAdornment position="end">
            { 
            textFieldDepositError ? 
              <IconButton disableRipple sx={{
                '&:hover': {
                  background: 'transparent',
                  cursor: 'auto'
                }
              }}>
                <ErrorCircleFilledIcon />
              </IconButton> :
              <IconButton onClick={handleDelete} disableRipple>
                {<CancelIcon />}
              </IconButton>
            }
          </InputAdornment>
          : null
        }
        sx={[
          { 
            caretColor: textFieldDepositError ? '#DC362E' : '#2196F3',
            color: '#616165',
            '& input[type=number]': {
              '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
          },
          {
            '&.Mui-focused': {
              background: '#FBFDFF',
            }
          },
        ]}
      />
      {
        textFieldDepositError ?
          <FormHelperText>{errorMsg}</FormHelperText>
        : null
      }
    </FormControl>
  );
}
