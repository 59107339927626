import { Box, Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { feesOptimizationData } from '../../../../data/states/FeesOptimizationData';
import { DEAL_REVIEW } from '../../../../data/states/States';
import { setActiveDepositState } from '../../../../features/activeState/activeDepositStateSlice';
import { TonalButton } from '../../../buttons/TonalButton';
import { OutlinedCard } from '../../../cards/OutlinedCard';
import { SwitchWithIcon } from '../../../SwitchWithIcon';
import { Notification } from '../../../notifications/Notification';
import { feesOptimizationNotificationData } from '../../../../data/FeesOptimizationNotificationData';
import { EstimatedFee } from '../../../estimatedComponents/EstimatedFee';
import { RootState } from '../../../../app/store';
import { setFeeValue } from '../../../../features/feeValueSlice';
import { setFeeValueETH } from '../../../../features/feeValueETHSlice';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import { contractAddressDsf, abiDsf } from '../../../../data/smartcontracts/SmartContractData';
import { setEstimatedFeeError } from '../../../../features/estimatedFeeErrorSlice';

export function FeesOptimization() {
  const switchFeesOptimization = useSelector((state: RootState) => state.switchFeesOptimization.value);
  const feeValue = useSelector((state: RootState) => state.feeValue.value);
  const approval = useSelector((state: RootState) => state.approval.value);
  const estimatedFeeError = useSelector((state: RootState) => state.estimatedFeeError.value);
  const dispatch = useDispatch();

  const handleClickNext = () => {
    dispatch(setActiveDepositState(DEAL_REVIEW));
  }

  const setNewFeeValue = () => {
    if (switchFeesOptimization) {
      getDelegateDepositFee();
    } else {
      getCasualDepositFee();
    }
  }

  async function getAccounts() {
    try {
      return await (window as any).ethereum.request({ method: "eth_requestAccounts" });
    } catch (error) {
      console.log(error);
    }
  }

  const getCasualDepositFee = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiDsf as AbiItem[], contractAddressDsf);
      const fromAddress = (await getAccounts())[0];
      console.log("AA");
      
      try {
      await contract.methods.deposit([0,0,10000]).estimateGas({from: fromAddress})
      .then(function(gasAmount : any){
        web3.eth.getGasPrice()
        .then((responseGasPrice) => {
          
          fetch(`https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD`)
          .then((response) => response.json())
          .then((actualData) => {

            const weiFee = BigInt(responseGasPrice) * BigInt(gasAmount);
            let ethCommission = web3.utils.fromWei(String(weiFee), 'ether');
            if (ethCommission.length > 9) {
              ethCommission = ethCommission.substring(0, 9);
            }
            ethCommission = (parseFloat(ethCommission) * 1.7).toFixed(6);
            dispatch(setFeeValueETH(ethCommission));
            const finallyFee = (parseFloat(ethCommission) * actualData.USD).toFixed(2);
            console.log(finallyFee);
            dispatch(setFeeValue(finallyFee));

            web3.eth.getBalance(fromAddress)
            .then((ethBalanceWei) => {
              const ethBalanceEth = web3.utils.fromWei(ethBalanceWei, 'ether');
              if (parseFloat(ethBalanceEth) < parseFloat(ethCommission)) {
                dispatch(setEstimatedFeeError(true));
              } else {
                dispatch(setEstimatedFeeError(false));
              }
            });
          });
        });
      })
      }
      catch {
        dispatch(setFeeValue('40'));
        dispatch(setFeeValueETH("0.035"));
      }
    }
  }

  const getDelegateDepositFee = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiDsf as AbiItem[], contractAddressDsf);
      const fromAddress = (await getAccounts())[0];
      
      try {
      await contract.methods.feesOptimizationDeposit([0,0,10000]).estimateGas({from: fromAddress})
      .then(function(gasAmount : any){
        web3.eth.getGasPrice()
        .then((responseGasPrice) => {
          
          fetch(`https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD`)
          .then((response) => response.json())
          .then((actualData) => {

            const weiFee = BigInt(responseGasPrice) * BigInt(gasAmount);
            let ethCommission = web3.utils.fromWei(String(weiFee), 'ether');
            if (ethCommission.length > 9) {
              ethCommission = ethCommission.substring(0, 9);
            }
            ethCommission = (parseFloat(ethCommission) * 1.7).toFixed(6);
            dispatch(setFeeValueETH(ethCommission));
            const finallyFee = (parseFloat(ethCommission) * actualData.USD).toFixed(2);
            console.log(finallyFee);
            dispatch(setFeeValue(finallyFee));

            web3.eth.getBalance(fromAddress)
            .then((ethBalanceWei) => {
              const ethBalanceEth = web3.utils.fromWei(ethBalanceWei, 'ether');
              if (parseFloat(ethBalanceEth) < parseFloat(ethCommission)) {
                dispatch(setEstimatedFeeError(true));
              } else {
                dispatch(setEstimatedFeeError(false));
              }
            });
          });
        });
      })
      }
      catch (err : any) {
        console.log(err);
        dispatch(setFeeValue('2.1'));
        dispatch(setFeeValueETH("0.0019"));
      }
    }
  }

  React.useMemo(setNewFeeValue, [switchFeesOptimization]);

  return (
    <OutlinedCard avatar={!approval ? feesOptimizationData.avatar : feesOptimizationData.avatarApproval} title={feesOptimizationData.title} >
      <Grid item xs={12}>
        <Box>
          <Notification 
            childrenBefore={<SwitchWithIcon />}
            descriptionText={switchFeesOptimization ? feesOptimizationNotificationData.descriptionOptimizeFeesTrue : feesOptimizationNotificationData.descriptionOptimizeFeesFalse}
            childrenAfter={
              <EstimatedFee text={switchFeesOptimization ? "Estimated optimized fee" : "Estimated standard fee"} fee={feeValue} error={estimatedFeeError} />
            }
          />
        </Box>
      </Grid>
      <Box mt={5}>
        <TonalButton
          disabled={estimatedFeeError}
          text={feesOptimizationData.buttonText}
          onClick={handleClickNext} />
      </Box>
    </OutlinedCard>
  );
}
