import { Box } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { Deal } from '../../../../data/Deals';
import { dealReviewWithdrawData } from '../../../../data/states/DealReviewWithdrawData';
import { metaMaskConfirmationData } from '../../../../data/states/MetaMaskConfirmationData';
import { DEAL_REVIEW, METAMASK_CONFIRMATION, PROCESS_COMPLETED } from '../../../../data/states/States';
import { setActiveWithdrawState, setDisabledWithdrawState } from '../../../../features/activeState/activeWithdrawStateSlice';
import { FilledButton } from '../../../buttons/FilledButton';
import { OutlinedCard } from '../../../cards/OutlinedCard';
import { DescriptionButton } from '../../../DescriptionButton';
import { MetamaskGrayIcon, MoneyIcon, PigIcon } from '../../../Icons';
import { ResultBox } from '../../../ResultBox';
import { BodyLarge } from '../../../text/body/BodyLarge';
import { BodyMedium } from '../../../text/body/BodyMedium';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import { contractAddressDsf, abiDsf } from '../../../../data/smartcontracts/SmartContractData';
import { setlinkEtherscanWithdrawn } from '../../../../features/etherscanLinks/linkEtherscanWithdrawnSlice';


export function MetaMaskConfirmation() {
  const dropdownMenuWithdrawValue: Deal = useSelector((state: RootState) => state.dropdownMenuWithdrawValue);
  const estimatedIncome = useSelector((state: RootState) => state.estimatedIncome.value);
  const receiveSum = useSelector((state: RootState) => state.receiveSum.value);

  const dispatch = useDispatch();

  const changeStateToDealReviewWithdraw = () => {
    dispatch(setDisabledWithdrawState(METAMASK_CONFIRMATION));
    setTimeout(() => dispatch(setActiveWithdrawState(DEAL_REVIEW)), 10);
  } 

  async function getAccounts() {
    try {
      return await (window as any).ethereum.request({ method: "eth_requestAccounts" });
    } catch (error) {
      console.log(error);
    }
  }

  const withdrawUsdt = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiDsf as AbiItem[], contractAddressDsf);
      const fromAddress = (await getAccounts())[0];
      let numb : string = "0";
      if (dropdownMenuWithdrawValue.count != undefined)
      {
        numb = dropdownMenuWithdrawValue.count;
      }
      const withdUsdt = web3.utils.toWei(numb, 'ether');

      console.log("lpShares = " + withdUsdt);
      console.log("tokenAmounts = [0,0,0]");
      console.log("withdrawalType = 1");
      console.log("tokenIndex = 2");

      contract.methods.withdraw(withdUsdt,[0,0,0],1,2).send({from: fromAddress}, function(error : any, transactionHash : any){
        if (error != null) {
          console.log(error);
          changeStateToDealReviewWithdraw();
        } else {
          dispatch(setlinkEtherscanWithdrawn("https://etherscan.io/tx/" + transactionHash));
          dispatch(setActiveWithdrawState(PROCESS_COMPLETED));
          console.log('deposit');
          console.log(transactionHash);
        }
    });
    }
  }

  React.useEffect(() => {
    withdrawUsdt();    
  }, []);

  return (
    <OutlinedCard avatar={metaMaskConfirmationData.avatar} title={metaMaskConfirmationData.title}>
      <Box>
        <Box>
          <BodyLarge text='You are going to withdraw:' color='#000000' />
        </Box>
        <Box mt={2}>
          <ResultBox icon={dropdownMenuWithdrawValue.icon} helperText={'Coin and amount'}>
            <BodyMedium text={`${dropdownMenuWithdrawValue.count} ${dropdownMenuWithdrawValue.name}`} color='#000000' />
          </ResultBox>
        </Box>
        <Box mt={2}>
          <ResultBox icon={<PigIcon />} helperText={'Estimated income'}>
            <BodyMedium text={`${estimatedIncome} ${dropdownMenuWithdrawValue.name}`} color='#000000' />
          </ResultBox>
        </Box>
        <Box mt={2}>
          <ResultBox icon={<MoneyIcon />} helperText={'You will receive'}>
            <Box display={'inline-flex'}>
              <BodyMedium text={`${receiveSum} ${dropdownMenuWithdrawValue.name}`} color='#000000' />
            </Box>
          </ResultBox>
        </Box>
      </Box>
      <Box mt={7.5}>
        <FilledButton
          disabled
          startIcon={dealReviewWithdrawData.buttonIcon}
          startIconDisabled={MetamaskGrayIcon()}
          text={dealReviewWithdrawData.buttonText}
          />
        {
        <Box mt={0.5}>
          <DescriptionButton descriptionText={dealReviewWithdrawData.buttonDesc} />
        </Box>
        }
      </Box>
    </OutlinedCard>
  );
}
