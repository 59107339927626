import React from "react";

export function DAIIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_434_2804)">
    <path d="M12 0C18.6277 0 24 5.37225 24 12C24 18.6277 18.6277 24 12 24C5.37225 24 0 18.6277 0 12C0 5.37225 5.37225 0 12 0ZM11.8718 6H6.95775V9.8955H5.25V11.2913H6.95775V12.756H5.25V14.151H6.95775V18H11.8718C14.8268 18 17.0753 16.4287 17.94 14.1517H19.5V12.7568H18.282C18.312 12.5093 18.327 12.2557 18.327 11.9992V11.9648C18.327 11.7368 18.315 11.5118 18.2917 11.2913H19.5V9.89625H17.9692C17.1262 7.5855 14.8605 6 11.8718 6ZM16.4348 14.1517C15.6795 15.708 14.0565 16.7482 11.8718 16.7482H8.33175V14.1517H16.4348ZM16.8577 11.2913C16.8892 11.5215 16.9058 11.7577 16.9058 11.9992V12.033C16.9058 12.2798 16.8885 12.5205 16.8555 12.756H8.331V11.2913H16.8585H16.8577ZM11.8725 7.2495C14.067 7.2495 15.6952 8.3175 16.446 9.8955H8.331V7.2495H11.871H11.8725Z" fill="#F5AC37"/>
    </g>
    <defs>
    <clipPath id="clip0_434_2804">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
}