import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const approvalUSDCSlice = createSlice({
  name: 'approvalUSDC',
  initialState: {
    value: false,
  },
  reducers: {
    setApprovalUSDC: (state: any, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    }
  }
});

export const { setApprovalUSDC } = approvalUSDCSlice.actions;

export default approvalUSDCSlice.reducer;