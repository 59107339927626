import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const isFirstStartSlice = createSlice({
  name: 'isFirstStart',
  initialState: {
    value: true,
  },
  reducers: {
    setIsFirstStart: (state: any, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    }
  }
});

export const { setIsFirstStart } = isFirstStartSlice.actions;

export default isFirstStartSlice.reducer;