import { Alert } from '@mui/material';
import React from 'react';
import { CheckCircleOutlineIcon } from '../../Icons';
import { LabelSmall } from '../../text/label/LabelSmall';

export interface IAlertProps {
  text: string;
}

export function SuccessAlert({ text }: IAlertProps) {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(false);
    return () => {
      setTimeout(() => setIsLoading(true), 5000);
    }
  }, []);

  return (
    <Alert
      icon={<CheckCircleOutlineIcon />}
      severity="success"
      sx={[
        {
          '&': {
            width: '100%',
            backgroundColor: '#D9FCFB',
            borderRadius: '8px',
            padding: '8px',
            textAlign: 'left',
            transition: 'opacity .3s ease-in-out',
            opacity: isLoading ? '0' : '1',
          }
        },
      ]}
    >
      <LabelSmall text={text} color={'#008267'} />
    </Alert>
  );
}
