import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const linkEtherscanWithdrawnSlice = createSlice({
  name: 'linkEtherscanWithdrawn',
  initialState: {
    value: "",
  },
  reducers: {
    setlinkEtherscanWithdrawn: (state: any, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { setlinkEtherscanWithdrawn } = linkEtherscanWithdrawnSlice.actions;

export default linkEtherscanWithdrawnSlice.reducer;