import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setCheckedMaxWalletBalanceClick } from '../../features/checkedMaxWalletBalance/checkedMaxWalletBalanceClickSlice';
import { setCheckedMaxWalletBalance } from '../../features/checkedMaxWalletBalance/checkedMaxWalletBalanceSlice';

export interface ICheckboxProps {
  label: string;
  disabled?: boolean;
}

export function CheckboxWithLabel({ label, disabled = false }: ICheckboxProps) {
  const checkedMaxWalletBalance = useSelector((state: RootState) => state.checkedMaxWalletBalance.value);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCheckedMaxWalletBalance(event.target.checked));
    dispatch(setCheckedMaxWalletBalanceClick(true));
  };

  return (
    <FormControlLabel 
      control={<Checkbox
        checked={checkedMaxWalletBalance}
        onChange={handleChange}
        color="primary" 
        disableRipple 
        disabled={disabled} />} 
      label={label} />
  );
}
