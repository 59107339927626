import { MetamaskIcon } from "../../components/Icons";

export const beforeYouStartData = {
  avatar: "!",
  title: "Before you start",
  list: [
    "Make sure to connect your MetaMask wallet",
    "Check your wallet balance",
  ],
  buttonStartIcon: MetamaskIcon(),
  buttonText: "Connect Wallet",
  buttonDesc: "You will be redirected to Metamask to connect your wallet",
}