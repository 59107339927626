import { Box, FormHelperText, Icon, Typography } from '@mui/material';
import React from 'react';

export interface INotificationSmallProps {
  icon: React.ReactNode;
  text: string;
  helperText?: string;
  disabled?: boolean;
}

export function NotificationSmall({ icon, text, helperText, disabled = false }: INotificationSmallProps) {
  return (
    <Box>
      <Box 
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          padding: '16px',
          gap: '16px',
          backgroundColor: disabled ? '#EEEEF2' : '#EDEFF2',
          color: disabled ? '#BDBFC1' : '#616365',
          borderRadius: '4px',
        },
      ]}>
        <Icon sx={[
          {
            'svg path': {
              fill: disabled ? '#BDBFC1' : '#616365'
            }
          }
        ]}>
          {icon}
        </Icon>
        <Typography>
          {text}
        </Typography>        
      </Box>
      <FormHelperText sx={[
        {
          paddingLeft: '19px',
          color: disabled ? '#9EA0A2' : '#616165',
        }
      ]}>{helperText}
      </FormHelperText>
    </Box>
  );
}
