import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const textFieldBlurSlice = createSlice({
  name: 'textFieldBlur',
  initialState: {
    value: false,
  },
  reducers: {
    setTextFieldBlur: (state: any, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    }
  }
});

export const { setTextFieldBlur } = textFieldBlurSlice.actions;

export default textFieldBlurSlice.reducer;