import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const progressDepositListSlice = createSlice({
  name: 'progressDepositList',
  initialState: {
    value: [],
  },
  reducers: {
    setProgressDepositList: (state: any, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { setProgressDepositList } = progressDepositListSlice.actions;

export default progressDepositListSlice.reducer;