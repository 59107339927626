import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { processCompletedData } from '../../../data/states/ProcessCompletedData';
import { AMOUNT, DEAL_REVIEW, FEES_OPTIMIZATION, METAMASK_APPROVAL, UNDERLYING_ASSET } from '../../../data/states/States';
import { setActiveDepositState, setDisabledDepositState } from '../../../features/activeState/activeDepositStateSlice';
import { OutlinedButton } from '../../buttons/OutlinedButton';
import { OutlinedCard } from '../../cards/OutlinedCard';
import { OkeyIcon } from '../../Icons';
import { BodyLarge } from '../../text/body/BodyLarge';
import { TitleLarge } from '../../text/title/TitleLarge';

export interface IProcessCompletedProps {
  isDeposit?: boolean;
}

export function ProcessCompleted({ isDeposit = false }: IProcessCompletedProps) {
  const linkEtherscanDeposit = useSelector((state: RootState) => state.linkEtherscanDeposit.value);
  const linkEtherscanWithdrawn = useSelector((state: RootState) => state.linkEtherscanWithdrawn.value);
  const switchFeesOptimization = useSelector((state: RootState) => state.switchFeesOptimization.value);

  const dispatch = useDispatch();

  const handleHomepageClick = () => {
    // обнулить все хранилище
    dispatch(setActiveDepositState(UNDERLYING_ASSET));
  }

  return (
    <OutlinedCard end title={processCompletedData.title}>
      <Box display={'flex'} alignItems={'center'} flexDirection={'column'} mt={12.75}>
        <Box>
          <OkeyIcon />
        </Box>
        <Box mt={2}>
          <TitleLarge text={isDeposit ? 'Deposit Placed' : 'Deposit Withdrawn'} color={'#000000'} />
        </Box>
        { isDeposit ?
            switchFeesOptimization ?
            <Box mt={2} display={'flex'} justifyContent={'center'}>
              <BodyLarge text={'Your Etherscan link will be ready within 24 hours.'} color={'#000000'} />
            </Box>
            :
            <Box mt={2} display={'flex'} justifyContent={'center'} gap={0.5}>
              <BodyLarge text={'View deal in'} color={'#000000'} />
              <Link target={'_blank'} href={isDeposit ? linkEtherscanDeposit : linkEtherscanWithdrawn} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                <Typography 
                  color={'#1943E4'}
                  sx={{ borderBottom: '1px dashed #1943E4' }}>
                    Etherscan
                </Typography>
              </Link>
            </Box>
          :
          <Box mt={2} display={'flex'} justifyContent={'center'} gap={0.5}>
            <BodyLarge text={'View deal in'} color={'#000000'} />
            <Link target={'_blank'} href={isDeposit ? linkEtherscanDeposit : linkEtherscanWithdrawn} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
              <Typography 
                color={'#1943E4'}
                sx={{ borderBottom: '1px dashed #1943E4' }}>
                  Etherscan
              </Typography>
            </Link>
          </Box>
        }
      </Box>
      <Box mt={8.25}>
        <Link href="" sx={{ textDecoration: 'none' }}>
          <OutlinedButton
            text={processCompletedData.buttonText}
            // onClick={handleHomepageClick}
            />
        </Link>
      </Box>
    </OutlinedCard>
  );
}
