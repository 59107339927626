import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { estimatedRateData } from '../../../data/estimatedComponents/EstimatedRateData';
import { LightTooltip } from '../../LightTooltip';
import { BodySmall } from '../../text/body/BodySmall';

export interface IEstimatedRateProps {
  rate: string;
  disabled?: boolean;
}

export function EstimatedRate({ rate, disabled = false }: IEstimatedRateProps) {
  const dropdownMenuValue = useSelector((state: RootState) => state.dropdownMenuValue);

  return (
    <Box>
      <LightTooltip title={<Box>
        <BodySmall text={estimatedRateData.tooltipText[0]} color='#000000' />
        <BodySmall text={estimatedRateData.tooltipText[1]} color='#1F4CF0' />
        <BodySmall text={estimatedRateData.tooltipText[2]} color='#000000' />
      </Box>} arrow placement="top">
        <Box width={'fit-content'}  sx={{ cursor: 'pointer', }}>
          <Typography 
            color={disabled ? '#9E9EA2' : '#1943E4'}
            sx={{ borderBottom: disabled ? '1px dashed #424446' : '1px dashed #1943E4' }}>
              {dropdownMenuValue.name} {estimatedRateData.title}
          </Typography>
        </Box>
      </LightTooltip>
      <Box mt={1}>
        <Typography color={disabled ? '#BDBFC1' : '#000000'}>
          {rate}
        </Typography>
      </Box>
    </Box>
  );
}
