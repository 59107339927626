import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const estimatedFeeErrorSlice = createSlice({
  name: 'estimatedFeeError',
  initialState: {
    value: false,
  },
  reducers: {
    setEstimatedFeeError: (state: any, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    }
  }
});

export const { setEstimatedFeeError } = estimatedFeeErrorSlice.actions;

export default estimatedFeeErrorSlice.reducer;