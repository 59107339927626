import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const switchFeesOptimizationSlice = createSlice({
  name: 'switchFeesOptimization',
  initialState: {
    value: true,
  },
  reducers: {
    setSwitchFeesOptimization: (state: any, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    }
  }
});

export const { setSwitchFeesOptimization } = switchFeesOptimizationSlice.actions;

export default switchFeesOptimizationSlice.reducer;