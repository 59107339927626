import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_STATE, State } from '../../data/states/States';

export const activeDepositStateSlice = createSlice({
  name: 'activeDepositState',
  initialState: INITIAL_STATE,
  reducers: {
    setActiveDepositState: (state: any, action: PayloadAction<State>) => {
      state.name = action.payload.name;
      state.isActive = true;
    }, 
    setDisabledDepositState: (state: any, action: PayloadAction<State>) => {
      state.name = action.payload.name;
      state.isActive = false;
    }, 
  }
});

export const { setActiveDepositState, setDisabledDepositState } = activeDepositStateSlice.actions;

export default activeDepositStateSlice.reducer;