import { Box, Button, Card, CardContent, CardHeader, LinearProgress, List, ListItem, ListItemText } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { progressCardData } from '../../../data/progressCards/ProgressCardData';
import { progressCardDepositData } from '../../../data/progressCards/ProgressCardDepositData';
import { AMOUNT, BEFORE_YOU_START, DEAL_REVIEW, FEES_OPTIMIZATION, METAMASK_APPROVAL, PROCESS_COMPLETED, State, UNDERLYING_ASSET } from '../../../data/states/States';
import { setActiveDepositState, setDisabledDepositState } from '../../../features/activeState/activeDepositStateSlice';
import { DescriptionButton } from '../../DescriptionButton';
import { ICardProps } from '../OutlinedCard';

function RenderList(list: Array<State>) {
  const dispatch = useDispatch();

  const handleStateClick = (state: State) => {
    dispatch(setActiveDepositState(state));
  }

  return list.map((state, index) => 
    <ListItem key={index} component="div" disableGutters sx={[
      {
        color: state.isActive ? '#212324' : '#757779',
        padding: '10px 0',
      }
    ]}>
      <Button variant='text' color='inherit' disableRipple
        disabled={state.isActive ? false : true}
        onClick={() => handleStateClick(state)}
        sx={[
          {
            '&:hover': {
              backgroundColor: 'transparent',
            }
          },
          {
            '&.Mui-disabled': {
              color: 'inherit'
            }
          }
        ]}>
        <ListItemText primary={`${state.name}`} sx={[
          {
            margin: 0,
          },
          {
            '.MuiTypography-root': {
                fontWeight: state.isActive ? 600 : 400,
                fontSize: 14,
                lineHeight: '20px',
                letterSpacing: '0.25px',
                textAlign: 'left',

            }
          }
        ]}/>
      </Button>
    </ListItem>
  )
}

export function ProgressCard() {
  const list = [
    UNDERLYING_ASSET,
    METAMASK_APPROVAL,
    AMOUNT,
    FEES_OPTIMIZATION,
    DEAL_REVIEW,
  ];
  const listWithoutApproval =[
    UNDERLYING_ASSET,
    AMOUNT,
    FEES_OPTIMIZATION,
    DEAL_REVIEW,
  ];

  const [progress, setProgress] = React.useState(0);
  const activeDepositState = useSelector((state: RootState) => state.activeDepositState);
  const dropdownMenuValue = useSelector((state: RootState) => state.dropdownMenuValue);
  const approval = useSelector((state: RootState) => state.approval.value);
  const isApprove = useSelector((state: RootState) => state.isApprove.value);

  const generateProgressList = () => {
    if (isApprove) {
      return progressCardDepositData.listWithoutApproval;
    }
    return progressCardDepositData.list;
  }

  const [progressList, setProgressList] = React.useState<Array<State>>([]);

  React.useEffect(() => {
    setProgressList(generateProgressList());
  }, [isApprove]);

  React.useEffect(() => {
    if (activeDepositState.name === BEFORE_YOU_START.name) {
      setProgress(0);
      if (isApprove) {
        setProgressList(listWithoutApproval);
      } else {
        setProgressList(list);
      }
    }
    else if (activeDepositState.name === UNDERLYING_ASSET.name) {
      setProgress(5.128);
    }
    else if (activeDepositState.name === PROCESS_COMPLETED.name) {
      setProgress(100);
      setProgressList(progressCardDepositData.listWithoutApprovalDisabled);
    } else {
      const currentIndex = progressList.map(state => state.name).indexOf(activeDepositState.name);
      const percent = ((currentIndex) / progressList.length) * 100;
      setProgress(percent);
    }
  }, [activeDepositState]);

  const activeList = () => {
    const currentIndex = progressList.map(state => state.name).indexOf(activeDepositState.name);
    if (currentIndex !== - 1) {
      progressList[currentIndex] = activeDepositState;
    }
  }

  React.useMemo(activeList, [activeDepositState, dropdownMenuValue]);

  return (
    <Card sx={[
      {
        borderRadius: '12px',
        border: '2px solid #D9EDFD',
        boxShadow: 'none',
        backgroundColor: '#F5F5F9',
        height: '100%'
      }
    ]}>
      <CardHeader 
        title={
          <LinearProgress variant="determinate" value={progress} sx={[
            {
              height: '8px',
              borderRadius: '6px',
            },
            {
              '&': {
                backgroundColor: '#E0E0E4',
              },
            }
          ]}/>
        }
        sx={[
        {
          backgroundColor: '#ffffff',
          padding: '32px 16px'
        }
      ]}>
      </CardHeader>
      <CardContent sx={[
        {
        }
      ]}>
        <List sx={{
          padding: '6px 0',
        }}>
          {RenderList(progressList)}
        </List>
        {
          activeDepositState.name === BEFORE_YOU_START.name || 
          activeDepositState.name === PROCESS_COMPLETED.name ? 
          null :
          // 14 7 15.5
          // dropdownMenuValue.name && approval ? 14 : dropdownMenuValue && !approval ? 15.5 : 7
          <Box mt={dropdownMenuValue.name && approval ? 14.5 : dropdownMenuValue.name && !approval ? 15.5 : 7} ml={1}>
            <DescriptionButton descriptionText={progressCardData.descriptionText} />
          </Box>
        }
      </CardContent>
    </Card>
  );
}
