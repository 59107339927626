export const dealFAQData = {
  list: [
    {
      summary: 'How Estimated Income is calculated?',
      details: [
        {
          text: 'DSF earns revenue in CRV and CVX tokens.',
        },
        {
          text: 'Estimated Income is calculated as: (the amount of accumulated DSF income * the current value of CVX and CRV) * by the percentage that your asset is in the total DSF user population.'
        },
        {
          text: 'When you withdraw your asset, the percentage of your income in the form of CVX and CRV tokens will be converted into your underlying asset (the asset in which you withdraw).',
        },
        {
          text: 'DSF team is working to implement an income optimization feature: this feature will allow you to convert the income received (CVX and CRV) to Stablecoin at certain intervals, i.e. fix profit. Further, the profit gained by DSF will report back to the strategy, it will have the effect of reinvestment, which increases the profitability of your funds. In addition, this feature will stabilize the profitability.',
        },
        {
          text: 'Currently, the yield in the Estimated Income column can fluctuate up and down depending on the current value of CRV and CVX tokens. After a deposit is made, you see a "snapshot" return, which can vary up or down with equal probability. In more than 12 months of testing this strategy, the yield has never dropped below 8%, and the maximum value at high market volatility reached 25%.',
        },
      ]
    },
  ]
}