import { Typography } from '@mui/material';
import React from 'react';
import { ITextProps } from '../../ItextProps';

export function TitleMedium({ text, color }: ITextProps) {
  return (
    <Typography color={color} sx={{
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px'
    }}>
      {text}
    </Typography>
  );
}
