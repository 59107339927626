import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { TabPanel } from './TabPanel';

export interface ITabsProps {
  tabList: Array<ITabProps>;
  panelList?: 
    Array<{
      index: number;
      children: React.ReactNode;
    }>;
}

export interface ITabProps {
  index: number;
  label: string;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function CustomTabs({ tabList = [], panelList = [] }: ITabsProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const TabList = tabList.map((tab) => 
    <Tab key={Math.random()} label={tab.label} {...a11yProps(tab.index)} disableRipple sx={[
      {
        minWidth: '130px',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
      },
      {
        '&.Mui-selected': {
          color: '#1764C0',
          backgroundColor: '#E3F2FD',
          borderRadius: '4px 4px 0px 0px',
        },
      },
    ]} />
  )

  const TabPanelList = panelList.map((panel) => 
    <TabPanel key={panel.index} index={panel.index} value={value} >
      {panel.children}
    </TabPanel>
  )

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange} sx={[
        {
          marginBottom: '32px',
        },
        {
          '& .MuiTabs-indicator': {
            backgroundColor: '#1764C0',
          },
        }
      ]} >
        {TabList}
      </Tabs>
      {TabPanelList}
    </Box>
  );
}
