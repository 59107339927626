import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const feeValueSlice = createSlice({
  name: 'feeValue',
  initialState: {
    value: "",
  },
  reducers: {
    setFeeValue: (state: any, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { setFeeValue } = feeValueSlice.actions;

export default feeValueSlice.reducer;