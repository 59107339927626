import { ITabsProps } from "../components/CustomTabs";
import { Deposit } from "../components/Deposit";
import { Withdraw } from "../components/Withdraw";

export const tabsData: ITabsProps = {
  tabList: [
    {
      index: 0,
      label: "Deposit",
    },
    {
      index: 1,
      label: "Withdraw",
    },
    {
      index: 2,
      label: "My deals",
    },
  ],
  panelList: [
    {
      index: 0,
      children: <Deposit />,
    },
    {
      index: 1,
      children: <Withdraw />,
    }
  ]
}