import { Box, Button, Card, CardContent, CardHeader, LinearProgress, List, ListItem, ListItemText } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { progressCardData } from '../../../data/progressCards/ProgressCardData';
import { progressCardWithdrawData } from '../../../data/progressCards/ProgressCardWithdrawData';
import { BEFORE_YOU_START, DEAL, DEAL_REVIEW, METAMASK_CONFIRMATION, PROCESS_COMPLETED, State, UNDERLYING_ASSET } from '../../../data/states/States';
import { setActiveWithdrawState, setDisabledWithdrawState } from '../../../features/activeState/activeWithdrawStateSlice';
import { DescriptionButton } from '../../DescriptionButton';
import { ICardProps } from '../OutlinedCard';

function RenderList(list: Array<State>) {
  const dispatch = useDispatch();

  const handleStateClick = (state: State) => {
    dispatch(setActiveWithdrawState(state));
  }
  
  return list.map((state, index) => 
    <ListItem key={index} component="div" disableGutters sx={[
      {
        color: state.isActive ? '#212324' : '#757779',
        padding: '10px 0',
      }
    ]}>
      <Button variant='text' color='inherit' disableRipple
        disabled={state.isActive ? false : true}
        onClick={() => handleStateClick(state)}
        sx={[
          {
            '&:hover': {
              backgroundColor: 'transparent',
            }
          },
          {
            '&.Mui-disabled': {
              color: 'inherit'
            }
          }
        ]}>
          <ListItemText primary={`${state.name}`} sx={[
            {
              margin: 0,
            },
            {
              '.MuiTypography-root': {
                  fontWeight: state.isActive ? 600 : 400,
                  fontSize: 14,
                  lineHeight: '20px',
                  letterSpacing: '0.25px',
                  textAlign: 'left',
              }
            }
          ]}/>
        </Button>
    </ListItem>
  )
}

export function ProgressCardWithdraw() {
  const [progress, setProgress] = React.useState(0);
  const activeWithdrawState = useSelector((state: RootState) => state.activeWithdrawState);
  const dropdownMenuWithdrawValue = useSelector((state: RootState) => state.dropdownMenuWithdrawValue);

  const generateProgressList = () => {
    return progressCardWithdrawData.list;
  }

  const listStart = [DEAL, DEAL_REVIEW, METAMASK_CONFIRMATION];

  const [progressList, setProgressList] = React.useState<Array<State>>([]);

  React.useEffect(() => {
    setProgressList(generateProgressList());
  }, []);

  React.useEffect(() => {
    if (activeWithdrawState.name === BEFORE_YOU_START.name) {
      setProgress(0);
      setProgressList(listStart);
    }
    else if (activeWithdrawState.name === DEAL.name) {
      setProgress(5.128);
    }
    else if (activeWithdrawState.name === PROCESS_COMPLETED.name) {
      setProgress(100);
      setProgressList(listStart);
      console.log('listStart', listStart);
    } else {
      const currentIndex = progressList.map(state => state.name).indexOf(activeWithdrawState.name);
      const percent = ((currentIndex) / progressList.length) * 100;
      setProgress(percent);
    }
  }, [activeWithdrawState]);

  const activeList = () => {
    const currentIndex = progressList.map(state => state.name).indexOf(activeWithdrawState.name);
    if (currentIndex !== - 1) {
      progressList[currentIndex] = activeWithdrawState;
      console.log('Changing', progressList);
    }
  }

  React.useMemo(activeList, [activeWithdrawState, dropdownMenuWithdrawValue]);

  return (
    <Card sx={[
      {
        borderRadius: '12px',
        border: '2px solid #D9EDFD',
        boxShadow: 'none',
        backgroundColor: '#F5F5F9',
        height: '100%'
      }
    ]}>
      <CardHeader 
        title={
          <LinearProgress variant="determinate" value={progress} sx={[
            {
              height: '8px',
              borderRadius: '6px',
            },
            {
              '&': {
                backgroundColor: '#E0E0E4',
              },
            }
          ]}/>
        }
        sx={[
        {
          backgroundColor: '#ffffff',
          padding: '32px 16px'
        }
      ]}>
      </CardHeader>
      <CardContent sx={[
        {
        }
      ]}>
        <List sx={{
          padding: '6px 0',
        }}>
          {RenderList(progressList)}
        </List>
        {
          activeWithdrawState.name === BEFORE_YOU_START.name || 
          activeWithdrawState.name === PROCESS_COMPLETED.name ? 
          null :
          <Box mt={20} ml={1}>
            <DescriptionButton descriptionText={progressCardData.descriptionText} />
          </Box>
        }
      </CardContent>
    </Card>
  );
}
