import { MetamaskGrayIcon, MetamaskIcon } from "../../components/Icons";

export const underlyingAssetData = {
  avatar: "1",
  title: "Underlying asset",
  buttonTextApproval: "Next: Approve in MetaMask",
  buttonText: "Next: Enter Amount",
  buttonIcon: MetamaskIcon(),
  buttonIconDisabled: MetamaskGrayIcon(),
  buttonDesc: "You will be redirected to Metamask to approve access to your coins",
}