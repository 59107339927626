export const beforeYouStartFAQData = {
  list: [
    {
      summary: 'What is Metamask?',
      details: [
        {
          text: 'A crypto-wallet that allows you to receive, store and send cryptocurrency assets using Ethereum network or networks built with the use for Ethereum technology. Metamask is available as an extension for Google Chrome, as well as for IOS and Android devices. Using Metamask only you have access to your assets.',
        },
        {
          text: 'More than 9 million users around the world currently use Metamask.',
        },
      ],
      links: [
        {
          text: 'Install Metamask',
          link: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=ru',
        },
        {
          text: 'Metamask official website',
          link: 'https://metamask.io/',
        }
      ]
    },
    {
      summary: 'What is Connect Wallet?',
      details: [
        {
          text: 'This is the feature that allows the DSF app to interact with your wallet. When you click on Connect Wallet you will see a Metamask Wallet pop-up where you need to agree to the interaction, which allows you to transfer funds to and from your DSF wallet. The DSF app only asks for the right to connect - no transactions can be made by the DSF app without your approval. Each transaction is approved by the user separately!',
        }  
      ]
    },
  ]
}