import { Box, Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { dropdownMenuData } from '../../../../data/selects/DropdownMenuData';
import { metaMaskApprovalData } from '../../../../data/states/MetaMaskApprovalData';
import { metaMaskApprovalNotificationData } from '../../../../data/MetaMaskApprovalNotificationSData';
import { AMOUNT, METAMASK_APPROVAL, UNDERLYING_ASSET } from '../../../../data/states/States';
import { underlyingAssetData } from '../../../../data/states/UnderlyingAssetData';
import { setActiveDepositState, setDisabledDepositState } from '../../../../features/activeState/activeDepositStateSlice';
import { setApproval } from '../../../../features/approve/approvalSlice';
import { TonalButton } from '../../../buttons/TonalButton';
import { OutlinedCard } from '../../../cards/OutlinedCard';
import { DescriptionButton } from '../../../DescriptionButton';
import { DropdownMenu } from '../../../selects/DropdownMenu';
import { EstimatedFee } from '../../../estimatedComponents/EstimatedFee';
import { EstimatedRate } from '../../../estimatedComponents/EstimatedRate';
import { Notification } from '../../../notifications/Notification';

import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import { contractAddressUsdt, abiUsdt } from '../../../../data/smartcontracts/SmartContractUsdtData';
import { contractAddressDai, abiDai } from '../../../../data/smartcontracts/SmartContractDaiData';
import { contractAddressUsdc, abiUsdc } from '../../../../data/smartcontracts/SmartContractUsdcData';
import { contractAddressDsf, abiDsf } from '../../../../data/smartcontracts/SmartContractData';
import { Coin } from '../../../../data/Coins';
import { setApproveClick } from '../../../../features/approve/approveClickSlice';
import { setShowLoader } from '../../../../features/showLoaderSlice';
import { setAccountClick } from '../../../../features/account/accountClickSlice';

export function MetaMaskApproval() {
  const approval = useSelector((state: RootState) => state.approval.value);
  const dropdownMenuValue: Coin = useSelector((state: RootState) => state.dropdownMenuValue);
  const apyUsdt = useSelector((state: RootState) => state.apyUsdt.value);
  const approveFee = useSelector((state: RootState) => state.approveFee.value);

  const dispatch = useDispatch();

  async function getAccounts() {
    try {
      return await (window as any).ethereum.request({ method: "eth_requestAccounts" });
    } catch (error) {
      console.log(error);
    }
  }

  const approve = async (coin: string) => {
    // if ((window as any).ethereum) {
    //   var web3 = new Web3(Web3.givenProvider);
    //   (window as any).contract = await new web3.eth.Contract(abi as AbiItem[], contractAddress);
    //   const fromAddress = (await getAccounts())[0];
    //   const transactionParameters = {
    //     to: contractAddress,
    //     from: fromAddress,
    //     value: '0',
    //     data: (window as any).contract.methods.approve("0x6B175474E89094C44Da98b954EedeAC495271d0F", 1).encodeABI(),
    //   };
    //   try {
    //     await (window as any).ethereum
    //     .request({
    //       method: "eth_sendTransaction",
    //       params: [transactionParameters],
    //     })
    //     .then((res: string[]) => {
    //       if ((res.length !== 0)) {
    //         dispatch(setApproval(true));
    //         // также задиспатчить конткретный апрувленный токен
    //         console.log('approve: true');
    //       }
    //     });
    //   } catch (error) {
    //     changeStateToUnderlyingAsset();
    //   }
    // }

    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      let abi;
      let contractAddress;

      if (coin === "USDC")
      {
        abi = abiUsdc;
        contractAddress = contractAddressUsdc;
      }
      if (coin === "DAI")
      {
         abi = abiDai;
         contractAddress = contractAddressDai;
      }
      if (coin === "USDT") {
        abi = abiUsdt;
        contractAddress = contractAddressUsdt;
      }

      var contract = await new web3.eth.Contract(abi as AbiItem[], contractAddress);
      const fromAddress = (await getAccounts())[0];

      try {
        await contract.methods.approve(contractAddressDsf, "10000000000000000000000000").send(
          {from: fromAddress}, function(error : any, transactionHash : any){
          if (error != null) {
            console.log(error);
          } else {
            dispatch(setShowLoader(true));
            console.log("Loading...");
          }
      })
        .then(function(transactionHash: any){
          dispatch(setShowLoader(false));
          dispatch(setApproveClick(true));
          dispatch(setApproval(true));
          console.log("Done :D");
          console.log(transactionHash);
      });
      } catch (error) {
        console.log(error);
        dispatch(setApproveClick(false));
        changeStateToUnderlyingAsset();
      }
    }
  }

  React.useEffect(() => {
    dispatch(setAccountClick(false));
    // вызываем открытие метамаска и передачу прав
    if (!approval) {
      approve(dropdownMenuValue.name)
    }
  }, []);

  const changeStateToAmount = () => {
    if (approval) {
      dispatch(setActiveDepositState(AMOUNT));
    }
  }

  const changeStateToUnderlyingAsset = () => {
    if (!approval) {
      dispatch(setDisabledDepositState(METAMASK_APPROVAL));
      setTimeout(() => dispatch(setActiveDepositState(UNDERLYING_ASSET)), 10);
    }
  } 

  React.useMemo(changeStateToAmount, [approval]);
  
  return (
    <OutlinedCard avatar={metaMaskApprovalData.avatar} title={metaMaskApprovalData.title}>
      <Grid container spacing={9}>
        <Grid item xs={6}>
          <DropdownMenu
            disabled
            labelName={dropdownMenuData.labelName} 
            menuItemsWithIcon={dropdownMenuData.menuItemsWithIcon} />
        </Grid>
        <Grid item xs={4} spacing={1}>
          <EstimatedRate
            disabled
            rate={`${apyUsdt}%`} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10}>
          <Box mt={2.25}>
            <Notification
              disabled
              title={metaMaskApprovalNotificationData.title} 
              descriptionText={metaMaskApprovalNotificationData.description}
              childrenAfter={<EstimatedFee disabled text={"Estimated fee"} fee={approveFee} />}
            />
          </Box>
        </Grid>
      </Grid> 

      <Box mt={2.25}>
        <TonalButton
          disabled={true}
          startIcon={underlyingAssetData.buttonIcon}
          startIconDisabled={underlyingAssetData.buttonIconDisabled} 
          text={underlyingAssetData.buttonTextApproval} />
        {
        <Box mt={0.5} ml={0.25}>
          <DescriptionButton descriptionText={underlyingAssetData.buttonDesc} />
        </Box>
        }
      </Box>
    </OutlinedCard>
  );
}
