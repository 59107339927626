import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const textFieldDepositErrorSlice = createSlice({
  name: 'textFieldDepositError',
  initialState: {
    value: false,
  },
  reducers: {
    setTextFieldDepositError: (state: any, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    }
  }
});

export const { setTextFieldDepositError } = textFieldDepositErrorSlice.actions;

export default textFieldDepositErrorSlice.reducer;