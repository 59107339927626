import { Box, Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { dropdownMenuData } from '../../../../data/selects/DropdownMenuData';
import { metaMaskApprovalNotificationData } from '../../../../data/MetaMaskApprovalNotificationSData';
import { AMOUNT, METAMASK_APPROVAL } from '../../../../data/states/States';
import { underlyingAssetData } from '../../../../data/states/UnderlyingAssetData';
import { setActiveDepositState } from '../../../../features/activeState/activeDepositStateSlice';
import { TonalButton } from '../../../buttons/TonalButton';
import { OutlinedCard } from '../../../cards/OutlinedCard';
import { DescriptionButton } from '../../../DescriptionButton';
import { DropdownMenu } from '../../../selects/DropdownMenu';
import { EstimatedFee } from '../../../estimatedComponents/EstimatedFee';
import { EstimatedRate } from '../../../estimatedComponents/EstimatedRate';
import { Notification } from '../../../notifications/Notification';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import { contractAddressUsdt, abiUsdt } from '../../../../data/smartcontracts/SmartContractUsdtData';
import { setApyUsdt } from '../../../../features/apyUsdtSlice';
import { setApproveFee } from '../../../../features/approve/approveFeeSlice';
import { contractAddressDsf, abiDsf } from '../../../../data/smartcontracts/SmartContractData';
import { setEstimatedFeeError } from '../../../../features/estimatedFeeErrorSlice';

export function UnderlyingAsset() {
  const dropdownMenuValue = useSelector((state: RootState) => state.dropdownMenuValue);
  const approval = useSelector((state: RootState) => state.approval.value);
  const apyUsdt = useSelector((state: RootState) => state.apyUsdt.value);
  const approveFee = useSelector((state: RootState) => state.approveFee.value);
  const estimatedFeeError = useSelector((state: RootState) => state.estimatedFeeError.value);

  const dispatch = useDispatch();

  const handleClickNext = () => {
    if (approval) {
      dispatch(setActiveDepositState(AMOUNT));
    } else {
      dispatch(setActiveDepositState(METAMASK_APPROVAL));
    }
  }

  // появление элементов после проверки аппрува
  const [checkingApproval, setCheckingApproval] = React.useState(true);
  React.useEffect(() => {
    if (dropdownMenuValue.name === "USDT")
    {
      setTimeout(() => setCheckingApproval(false), 1000);
    }
  }, [dropdownMenuValue.name]);

  React.useEffect(() => {
    console.log("dropdownMenuValue = " + dropdownMenuValue.name);
    console.log("approval = " + approval);
    console.log("checkingApproval = " + checkingApproval);

    checkEstimatedFee();

  }, [dropdownMenuValue.name]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      checkEstimatedFee();
    }, 7000);
    return () => clearInterval(interval);
  }, [dropdownMenuValue.name]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      getApproveUsdtFee();
    }, 7000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    getApproveUsdtFee();
  }, []);

  React.useEffect(() => {
    try {
      fetch(`https://yields.llama.fi/chart/8a20c472-142c-4442-b724-40f2183c073e`)
      .then((response) => response.json())
      .then((actualData) => {
        // const objData = JSON.parse(actualData);
        console.log(actualData.data[actualData.data.length - 1].apy);
        dispatch(setApyUsdt(String((actualData.data[actualData.data.length - 1].apy * 0.85).toFixed(2))));
      });
    }
    catch (error) {
      dispatch(setApyUsdt('10'));
      console.log(error);
    }
  }, []);

  async function getAccounts() {
    try {
      return await (window as any).ethereum.request({ method: "eth_requestAccounts" });
    } catch (error) {
      console.log(error);
    }
  }

  const checkEstimatedFee = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      const fromAddress = (await getAccounts())[0];
      await web3.eth.getBalance(fromAddress)
      .then((ethBalanceWei) => {
        fetch(`https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD`)
          .then((response) => response.json())
          .then((actualData) => {
            const ethBalanceEth = web3.utils.fromWei(ethBalanceWei, 'ether');
            if ((parseFloat(ethBalanceEth) * actualData.USD) < parseFloat(approveFee)) {
              dispatch(setEstimatedFeeError(true));
            } else {
              dispatch(setEstimatedFeeError(false));
            }
          });        
      });
    }
  }

  const getApproveUsdtFee = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiUsdt as AbiItem[], contractAddressUsdt);
      const fromAddress = (await getAccounts())[0];
      
      try {
      await contract.methods.approve(contractAddressDsf, "10000000000000000000000000").estimateGas({from: fromAddress})
      .then(function(gasAmount : any){
        web3.eth.getGasPrice()
        .then((responseGasPrice) => {
          fetch(`https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD`)
          .then((response) => response.json())
          .then((actualData) => {

            const weiFee = BigInt(responseGasPrice) * BigInt(gasAmount);
            const ethCommission = web3.utils.fromWei(String(weiFee), 'ether');
            const finallyFee = (parseFloat(ethCommission) * actualData.USD * 1.7).toFixed(2);
            console.log(finallyFee);
            dispatch(setApproveFee(finallyFee));
          });
        });
      })
      }
      catch {
        dispatch(setApproveFee('2.95'));
      }
    }
  }

  return (
    <OutlinedCard avatar={underlyingAssetData.avatar} title={underlyingAssetData.title}>
      <Grid container spacing={{ xs: 2, sm: 4, lg: 9 }}>
        <Grid item xs={12} sm={6}>
          <DropdownMenu 
            labelName={dropdownMenuData.labelName} 
            menuItemsWithIcon={dropdownMenuData.menuItemsWithIcon} />
        </Grid>
        {
          dropdownMenuValue.name ?
          
            <Grid item xs={12} sm={6} spacing={1}>
              <EstimatedRate rate={`${apyUsdt}%`} />
            </Grid>
          : null
        }
      </Grid>
      {
        dropdownMenuValue.name && !approval && !checkingApproval ?
        <Grid container>
          <Grid item xs={10}>
            <Box mt={2.25}>
              <Notification
                title={metaMaskApprovalNotificationData.title} 
                descriptionText={metaMaskApprovalNotificationData.description}
                childrenAfter={<EstimatedFee text={"Estimated fee"} fee={approveFee} error={estimatedFeeError} />}
              />
            </Box>
          </Grid>
        </Grid> 
        : null
      }

      <Box mt={dropdownMenuValue.name && !checkingApproval ? approval ? 27.5 : 2.25 : 27.5 }>
        <TonalButton
          disabled={dropdownMenuValue.name && !checkingApproval && !estimatedFeeError ? false : true}
          startIcon={!approval ? underlyingAssetData.buttonIcon : null}
          startIconDisabled={!approval ? underlyingAssetData.buttonIconDisabled : null} 
          text={!approval ? underlyingAssetData.buttonTextApproval : underlyingAssetData.buttonText}
          onClick={handleClickNext} />
        {
        !approval ? <Box mt={0.5} ml={0.25}>
          <DescriptionButton descriptionText={underlyingAssetData.buttonDesc} />
        </Box>
        : null
        }
      </Box>
    </OutlinedCard>
  );
}
