import { Typography } from '@mui/material';
import React from 'react';
import { ITextProps } from '../../ItextProps';

export function LabelSmall({ text, color }: ITextProps) {
  return (
    <Typography color={color} sx={{
      fontWeight: 500,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    }}>
      {text}
    </Typography>
  );
}
