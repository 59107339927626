import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Deal } from '../../data/Deals';

export const dropdownMenuWithdrawValueSlice = createSlice({
  name: 'dropdownMenuWithdrawValueSlice',
  initialState: {
    icon: null,
    name: "",
    count: "",
    date: "",
  },
  reducers: {
    setDropdownMenuWithdrawValue: (state: any, action: PayloadAction<Deal>) => {
      state.icon = action.payload.icon;
      state.name = action.payload.name;
      state.count = action.payload.count;
      state.date = action.payload.date;
    }
  }
});

export const { setDropdownMenuWithdrawValue } = dropdownMenuWithdrawValueSlice.actions;

export default dropdownMenuWithdrawValueSlice.reducer;