import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const receiveSumSlice = createSlice({
  name: 'estimatedIncome',
  initialState: {
    value: "1,990094",
  },
  reducers: {
    setReceiveSum: (state: any, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { setReceiveSum } = receiveSumSlice.actions;

export default receiveSumSlice.reducer;