import { DAIIcon, EthIcon, USDCIcon, USDTIcon, WBTCIcon } from "../components/Icons";

export interface Coin {
  icon: React.ReactNode;
  name: string;
  isActive?: boolean;
}

export const ETH = {
  icon: EthIcon(),
  name: "ETH - coming soon",
  isActive: false,
}

export const WBTC = {
  icon: WBTCIcon(),
  name: "WBTC - coming soon",
  isActive: false,
}

export const USDT = {
  icon: USDTIcon(),
  name: "USDT",
  isActive: true,
}

export const USDC = {
  icon: USDCIcon(),
  name: "USDC - coming soon",
  isActive: false,
}

export const DAI = {
  icon: DAIIcon(),
  name: "DAI - coming soon",
  isActive: false,
}