import { Box } from '@mui/material';
import React from 'react';
import { TitleMedium } from '../../text/title/TitleMedium';
import { CustomAccordion, IAccordionProps } from '../CustomAccordion';

export interface IFAQProps {
  list: Array<IAccordionProps>
}

export function FAQ({ list }: IFAQProps) {
  return (
    <Box>
      <Box mb={0.75}>
        <TitleMedium text='FAQ' color='#000000' />
      </Box>
      {
        list.map((item) => 
        <Box>
          <CustomAccordion summary={item.summary} details={item.details} links={item.links} />
        </Box>
        )
      }
    </Box>
  );
}
