import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const showLoaderSlice = createSlice({
  name: 'checkedMaxWalletBalanceClick',
  initialState: {
    value: false,
  },
  reducers: {
    setShowLoader: (state: any, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    }
  }
});

export const { setShowLoader } = showLoaderSlice.actions;

export default showLoaderSlice.reducer;