import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BEFORE_YOU_START, State } from '../../data/states/States';

export const activeWithdrawStateSlice = createSlice({
  name: 'activeWithdrawState',
  initialState: BEFORE_YOU_START,
  reducers: {
    setActiveWithdrawState: (state: any, action: PayloadAction<State>) => {
      state.name = action.payload.name;
      state.isActive = true;
    }, 
    setDisabledWithdrawState: (state: any, action: PayloadAction<State>) => {
      state.name = action.payload.name;
      state.isActive = false;
    }, 
  }
});

export const { setActiveWithdrawState, setDisabledWithdrawState } = activeWithdrawStateSlice.actions;

export default activeWithdrawStateSlice.reducer;