import { Avatar, Box } from '@mui/material';
import React from 'react';
import { BodySmall } from '../text/body/BodySmall';

export interface IResultBoxProps {
  icon: React.ReactNode;
  helperText: string;
  children?: React.ReactElement;
}

export function ResultBox({ icon, helperText, children }: IResultBoxProps) {
  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} gap={0.5}>
        <Avatar sx={{ bgcolor: '#E1F1FE', width: 28, height: 28 }}>
          {icon}
        </Avatar>
        {children}
      </Box>
      <Box mt={0.5}>
        <BodySmall text={helperText} color='#616165' />
      </Box>
    </Box>
  );
}
