import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const walletBalanceSlice = createSlice({
  name: 'walletBalance',
  initialState: {
    value: '',
  },
  reducers: {
    setWalletBalance: (state: any, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { setWalletBalance } = walletBalanceSlice.actions;

export default walletBalanceSlice.reducer;