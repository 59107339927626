import { Typography } from '@mui/material';
import React from 'react';

export interface IDescriptionButtonProps {
  descriptionText: string;
}

export function DescriptionButton({ descriptionText }: IDescriptionButtonProps) {
  return (
    <Typography variant='subtitle2' sx={{
      color: '#BDBFC1',
      fontSize: '0.688rem',
    }}>
      {descriptionText}
    </Typography>
  );
}
