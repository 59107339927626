import { Button } from '@mui/material';
import React from 'react';
import { IButtonProps } from '../FilledButton';

export function OutlinedButton({ text, startIcon, startIconDisabled, disabled = false, onClick = () => {} }: IButtonProps) {
  return (
    <Button 
      color="primary" 
      variant="outlined"
      disabled={disabled} 
      startIcon={!disabled ? startIcon : startIconDisabled} 
      disableRipple
      onClick={onClick}
      sx={[
      {
        padding: '10px 24px',
        borderRadius: '100px',
        border: '1px solid #9E9EA2',
      },
      {
        '&:hover': {
          backgroundColor: '#2196F314',
          border: '1px solid #9E9EA2'
        }
      },
      {
        '&:focus': {
          backgroundColor: '#2196F31F',
          border: '1px solid #2196F3'
        }
      },
      {
        '&:active': {
          backgroundColor: '#2196F31F',
          border: '1px solid #9E9EA2'
        }
      }
    ]}>
      {text}
    </Button>
  );
}
