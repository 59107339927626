import { Box, Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { amountData } from '../../../../data/states/AmountData';
import { Coin } from '../../../../data/Coins';
import { depositAmountTextFieldData } from '../../../../data/DepositAmountTextFieldData';
import { FEES_OPTIMIZATION } from '../../../../data/states/States';
import { walletBalanceCheckboxLabelData } from '../../../../data/WalletBalanceCheckboxLabelData';
import { walletBalanceData } from '../../../../data/WalletBalanceData';
import { setActiveDepositState } from '../../../../features/activeState/activeDepositStateSlice';
import { TonalButton } from '../../../buttons/TonalButton';
import { OutlinedCard } from '../../../cards/OutlinedCard';
import { CheckboxWithLabel } from '../../../CheckboxWithLabel';
import { NotificationSmall } from '../../../notifications/NotificationSmall';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import { contractAddressUsdt, abiUsdt } from '../../../../data/smartcontracts/SmartContractUsdtData';
import { contractAddressDai, abiDai } from '../../../../data/smartcontracts/SmartContractDaiData';
import { contractAddressUsdc, abiUsdc } from '../../../../data/smartcontracts/SmartContractUsdcData';
import { setWalletBalance } from '../../../../features/walletBalanceSlice';
import { OutlinedTextFieldNumber } from '../../../textfields/OutlinedTextFieldNumber';
import { setAccountClick } from '../../../../features/account/accountClickSlice';

export function Amount() {
  const dropdownMenuValue: Coin = useSelector((state: RootState) => state.dropdownMenuValue);
  const walletBalance: string = useSelector((state: RootState) => state.walletBalance.value);
  const approval = useSelector((state: RootState) => state.approval.value);
  const textFieldValue = useSelector((state: RootState) => state.textFieldValue.value);
  const textFieldDepositError = useSelector((state: RootState) => state.textFieldDepositError.value);

  const dispatch = useDispatch();

  const handleClickNext = () => {
    dispatch(setActiveDepositState(FEES_OPTIMIZATION));
  }

  async function getAccounts() {
    try {
      return await (window as any).ethereum.request({ method: "eth_requestAccounts" });
    } catch (error) {
      console.log(error);
    }
  }

  const getBalanceUsdt = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiUsdt as AbiItem[], contractAddressUsdt);
      const fromAddress = (await getAccounts())[0];

      try {
        await contract.methods.balanceOf(fromAddress).call({from: fromAddress})
        .then(function(result : any){
          result = web3.utils.fromWei(result, 'mwei');
          dispatch(setWalletBalance(result));
          // dispatch(setWalletBalance("60.5"));
      });
      } catch (error) {
        console.log(error);
        dispatch(setWalletBalance('0'));
      }
    }
  }

  const getBalanceUsdc = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiUsdc as AbiItem[], contractAddressUsdc);
      const fromAddress = (await getAccounts())[0];

      try {
        await contract.methods.balanceOf(fromAddress).call({from: fromAddress})
        .then(function(result : any){
          result = web3.utils.fromWei(result, 'mwei');
          dispatch(setWalletBalance(result));
      });
      } catch (error) {
        console.log(error);
        dispatch(setWalletBalance('0'));
      }
    }
  }

  const getBalanceDai = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiDai as AbiItem[], contractAddressDai);
      const fromAddress = (await getAccounts())[0];

      try {
        await contract.methods.balanceOf(fromAddress).call({from: fromAddress})
        .then(function(result : any){
          result = web3.utils.fromWei(result, 'ether');
          if (result.length <= 8)
          {
            dispatch(setWalletBalance(result));
          } else {
            const intBallance = parseInt(result);
            const amountInts = String(intBallance).length + 1 + 6;
            console.log(amountInts);
            result = result.substring(0, amountInts);
            dispatch(setWalletBalance(result));
          }
      });
      } catch (error) {
        console.log(error);
        dispatch(setWalletBalance('0'));
      }
    }
  }

  const account = useSelector((state: RootState) => state.account.value);

  React.useEffect(() => {
    console.log('Account in deposit (Amount): ', account);
    
    dispatch(setAccountClick(false));
    if (dropdownMenuValue.name === "USDT")
    {
      getBalanceUsdt();
    }
    if (dropdownMenuValue.name === "DAI")
    {
      getBalanceDai();
    }
    if (dropdownMenuValue.name === "USDC")
    {
      getBalanceUsdc();
    }
  }, []);


  return (
    <OutlinedCard avatar={!approval ? amountData.avatar : amountData.avatarApproval} title={amountData.title} >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={5}>
          <OutlinedTextFieldNumber
            id={depositAmountTextFieldData.id}
            helperText={depositAmountTextFieldData.helperText} />
        </Grid>
        <Grid item xs={12} sm={8} md={7}> 
          <NotificationSmall 
            icon={walletBalanceData.icon} 
            text={`${walletBalance} ${dropdownMenuValue.name}`} 
            helperText={walletBalanceData.helperText} />
        </Grid>
      </Grid>
      <Box mt={9.75}>
        <CheckboxWithLabel label={walletBalanceCheckboxLabelData.label} />
      </Box>
      <Box mt={9.75}>
        <TonalButton 
          disabled={textFieldDepositError || textFieldValue.length === 0}
          text={amountData.buttonText} 
          onClick={handleClickNext}
          />
      </Box>
    </OutlinedCard>
  );
}
