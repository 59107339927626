import { Box, Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { beforeYouStartFAQData } from '../../data/faq/BeforeYouStartFAQData';
import { dealFAQData } from '../../data/faq/DealFAQData';
import { BEFORE_YOU_START, DEAL, DEAL_REVIEW, INITIAL_STATE, METAMASK_CONFIRMATION, PROCESS_COMPLETED } from '../../data/states/States';
import { setActiveWithdrawState } from '../../features/activeState/activeWithdrawStateSlice';
import { SuccessAlert } from '../alerts/SuccessAlert';
import { ProgressCardWithdraw } from '../cards/ProgressCardWithdraw';
import { ContactUs } from '../contacts/ContactUs';
import { FAQ } from '../faq-section/FAQ';
import { BeforeYouStart } from '../states/BeforeYouStart';
import { InitialState } from '../states/InitialState';
import { ProcessCompleted } from '../states/ProcessCompleted';
import { DealReviewWithdraw } from '../states/withdraw/DealReviewWithdraw';
import { DealState } from '../states/withdraw/DealState/DealState';
import { MetaMaskConfirmation } from '../states/withdraw/MetaMaskConfirmation';

export function Withdraw() {
  const activeWithdrawState = useSelector((state: RootState) => state.activeWithdrawState);
  const account = useSelector((state: RootState) => state.account.value);
  const accountClick = useSelector((state: RootState) => state.accountClick.value);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (account) {
      dispatch(setActiveWithdrawState(DEAL));
      //dispatch(setActiveWithdrawState(PROCESS_COMPLETED));
    } else {
      dispatch(setActiveWithdrawState(BEFORE_YOU_START));
    }
  }, [account]);

  // connection alert
  const [connectionAlert, setConnectionAlert] = React.useState(false);

  React.useEffect(() => {
    if (account) {
      setConnectionAlert(true);
      setTimeout(() => setConnectionAlert(false), 5000);
    }
  }, [account]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          {
             activeWithdrawState.name === INITIAL_STATE.name ? <InitialState /> :
             activeWithdrawState.name === BEFORE_YOU_START.name ? <BeforeYouStart /> :
             activeWithdrawState.name === DEAL.name ? <DealState /> :
             activeWithdrawState.name === DEAL_REVIEW.name ? <DealReviewWithdraw /> :
             activeWithdrawState.name === METAMASK_CONFIRMATION.name ? <MetaMaskConfirmation /> :
             activeWithdrawState.name === PROCESS_COMPLETED.name ? <ProcessCompleted /> :
             null
          }
        </Grid>
        <Grid item xs={4}>
          <ProgressCardWithdraw />
        </Grid>
          {
            activeWithdrawState.name === DEAL.name && connectionAlert && accountClick ? 
              <>
                <Grid item xs={4} sx={{ minHeight: '72px' }}>
                  <SuccessAlert text='Your wallet has been connected' /> 
                </Grid>
                <Grid item xs={8}>
                </Grid>
              </> :
            null
          }
        <Grid item xs={8}>
          {
            activeWithdrawState.name === BEFORE_YOU_START.name ?
              <Box>
                <FAQ list={beforeYouStartFAQData.list} />
              </Box> :
            activeWithdrawState.name === DEAL.name ?
              <Box>
                <FAQ list={dealFAQData.list} />
              </Box> :
            null
          }
        </Grid>
        <Grid item xs={4} zIndex={2}>
          <Box display={'flex'} justifyContent={'flex-end'} mr={2}>
            <ContactUs />
          </Box>  
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid>
    </Box>
  );
}
