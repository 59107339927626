export const amountFAQData = {
  list: [
    {
      summary: 'What is the minimum amount I can deposit?',
      details: [
        {
          text: 'You can deposit any amount.',
        }
      ]
    },
    {
      summary: 'When can I withdraw my funds?',
      details: [
        {
          text: 'You can withdraw your funds the next day after depositing, provided you do not use the "Optimize Commissions" option, or starting from day 2 if you do.',
        },
      ]
    },
    {
      summary: 'Risks: Slippage',
      details: [
        {
          text: 'Insignificant risk, slippage in amount, not exceeding 0.5%, may occur when exchanging Stablecoins. This happens due to slight fluctuations in the exchange rate and usually slippage can range from -0.5% to +0.4% of the deposit amount (i.e. with some probability it is a risk of additional micro income).',
        },
      ]
    },
    {
      summary: 'Risks: The value of Stablecoin falls below $1',
      details: [
        {
          text: 'This could happen in the event of a smart contract collapse or in case of another unforeseen circumstances.',
        },
        {
          text: 'DSF uses time-tested Stablecoin of which 3 Stablecoins (USDT, USDC and DAI) are in the top 5 largest Stablecoins of the whole cryptocurrency market and Stablecoin MIM which has survived ups and downs of the cryptocurrency market.',
        },
        {
          text: 'Read more about Stablecoin',
          link: 'https://www.coingecko.com/en/categories/stablecoins?',
          textLink: 'here',
        },
      ]
    },
    {
      summary: 'Risks: The collapse of the applications that DSF works with is Curve and Convex',
      details: [
        {
          text: 'The probability of this risk  happening is extremely low due to the fact that Curve and Convex have the largest market share and are the fundamental applications of the entire Defi industry.',
        },
        {
          text: 'Curve is ranked number 1 in decentralized exchanges and number 4 in all applications of the Ethereum ecosystem. Over 56,208 unique users and over $3.5 billion in blockchain exchange pools, Convex in its turn holds over 50% of CRV tokens and has over 38 thousand of unique users and is one of the top 10 of all applications in the Ethereum ecosystem.',
        }
      ]
    },
    {
      summary: 'Risks: Cracking the DSF smart contract',
      details: [
        {
          text: 'This risk exists, but the DSF smart contract is designed so that it does not store your funds, but only acts as an intermediary between Curve, Convex and your wallet.',
        },
        {
          text: 'The deposit is made in one transaction, and when it is completed, an LP token is sent to your wallet, essentially a "receipt" for the deposited funds and the income from them. It can be compared to a key to a safe deposit box in a bank. Only the holder of this token can take the funds back. But this "receipt key" is protected in your wallet as securely as any other currency stored in that wallet, Stablecoin.'
        },
        {
          text: 'The DSF plans to audit the smart contract to further improve its security. The audit is conducted by auditors with an impeccable public reputation in the cryptocurrency market.'
        },
      ]
    },
  ]
}