import { Box } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { dealReviewData } from '../../../../data/states/DealReviewData';
import { AMOUNT, DEAL_REVIEW, FEES_OPTIMIZATION, PROCESS_COMPLETED } from '../../../../data/states/States';
import { setActiveDepositState, setDisabledDepositState } from '../../../../features/activeState/activeDepositStateSlice';
import { FilledButton } from '../../../buttons/FilledButton';
import { OutlinedCard } from '../../../cards/OutlinedCard';
import { DescriptionButton } from '../../../DescriptionButton';
import { DollarIcon } from '../../../Icons/DollarIcon';
import { PercentIcon } from '../../../Icons/PercentIcon';
import { ResultBox } from '../../../ResultBox';
import { BodyLarge } from '../../../text/body/BodyLarge';
import { BodyMedium } from '../../../text/body/BodyMedium';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import { contractAddressDsf, abiDsf } from '../../../../data/smartcontracts/SmartContractData';
import { setlinkEtherscanDeposit } from '../../../../features/etherscanLinks/linkEtherscanDepositSlice';

export function DealReview() {
  const dropdownMenuValue = useSelector((state: RootState) => state.dropdownMenuValue);
  const textFieldValue = useSelector((state: RootState) => state.textFieldValue.value);
  const feeValue = useSelector((state: RootState) => state.feeValue.value);
  const approval = useSelector((state: RootState) => state.approval.value);
  const switchFeesOptimization = useSelector((state: RootState) => state.switchFeesOptimization.value);
  const feeValueETH = useSelector((state: RootState) => state.feeValueETH.value);
  const apyUsdt = useSelector((state: RootState) => state.apyUsdt.value);

  const dispatch = useDispatch();

  const handlePlaceDepositClick = () => {
    // place deposit
    if (switchFeesOptimization == true) {
      depositDelegate();
      console.log("SMART");
    } else {
      console.log("CASUAL");
      depositCasual();
    }
  }

  async function getAccounts() {
    try {
      return await (window as any).ethereum.request({ method: "eth_requestAccounts" });
    } catch (error) {
      console.log(error);
    }
  }

  const depositCasual = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiDsf as AbiItem[], contractAddressDsf);
      const fromAddress = (await getAccounts())[0];
      const depUsdt = Number(parseFloat(textFieldValue) * 1000000);
      
      console.log("Address = " + fromAddress);
      console.log("Deposit USDT amount = " + depUsdt);
      contract.methods.deposit([0,0,depUsdt]).send({from: fromAddress}, function(error : any, transactionHash : any){
        if (error != null) {
          console.log(error);
        } else {
          dispatch(setlinkEtherscanDeposit("https://etherscan.io/tx/" + transactionHash));
          dispatch(setActiveDepositState(PROCESS_COMPLETED));
          console.log('deposit');
          console.log(transactionHash);
        }
    });
    }
  }

  const depositDelegate = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiDsf as AbiItem[], contractAddressDsf);
      const fromAddress = (await getAccounts())[0];
      const depUsdt = Number(parseFloat(textFieldValue) * 1000000);
      console.log("Deposit USDT amount = " + depUsdt);

      contract.methods.feesOptimizationDeposit([0,0,depUsdt]).send({from: fromAddress}, function(error : any, transactionHash : any){
        if (error != null) {
          console.log(error);
        } else {
          dispatch(setActiveDepositState(PROCESS_COMPLETED));
          dispatch(setlinkEtherscanDeposit("https://etherscan.io/tx/" + transactionHash));
          console.log('deposit');
          console.log(transactionHash);
        }
    });
    }
  }

  return (
    <OutlinedCard avatar={!approval ? dealReviewData.avatar : dealReviewData.avatarApproval} title={dealReviewData.title}>
      <Box>
        <Box>
          <BodyLarge text='You are going to deposit:' color='#000000' />
        </Box>
        <Box mt={2}>
          <ResultBox icon={dropdownMenuValue.icon} helperText={'Coin and amount'}>
            <BodyMedium text={`${dropdownMenuValue.name} ${textFieldValue}`} color='#000000' />
          </ResultBox>
        </Box>
        <Box mt={2}>
          <ResultBox icon={<PercentIcon />} helperText={'Estimated annual rate'}>
            <BodyMedium text={`${apyUsdt}%`} color='#000000' />
          </ResultBox>
        </Box>
        <Box mt={2}>
          <ResultBox icon={<DollarIcon />} helperText={'Estimated blockchain fee'}>
            <Box display={'inline-flex'}>
              <BodyMedium text={`$ ${feeValue}/`} color='#000000' />
              <BodyMedium text={`ETH ${feeValueETH}`} color='#9EA0A2' />
            </Box>
          </ResultBox>
        </Box>
      </Box>
      <Box mt={7.5}>
        <FilledButton
          startIcon={dealReviewData.buttonIcon}
          text={dealReviewData.buttonText}
          onClick={handlePlaceDepositClick}
           />
        {
        <Box mt={0.5} ml={0.25}>
          <DescriptionButton descriptionText={dealReviewData.buttonDesc} />
        </Box>
        }
      </Box>
    </OutlinedCard>
  );
}
