import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const estimatedIncomeSlice = createSlice({
  name: 'estimatedIncome',
  initialState: {
    value: "0",
  },
  reducers: {
    setEstimatedIncome: (state: any, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { setEstimatedIncome } = estimatedIncomeSlice.actions;

export default estimatedIncomeSlice.reducer;