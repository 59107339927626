import { Accordion, AccordionDetails, AccordionSummary, Box, Link } from '@mui/material';
import React from 'react';
import { ExpandIcon } from '../../Icons';
import { BodyMedium } from '../../text/body/BodyMedium';

export interface IAccordionProps {
  summary: string;
  details: Array<{
    text: string;
    textLink?: string;
    link?: string;
  }>;
  links?: Array<{
    text: string;
    link: string;
  }>
}

export function CustomAccordion({ summary, details, links }: IAccordionProps) {
  return (
    <Accordion sx={{
      boxShadow: 'none',
      borderRadius: 0,
    }}>
      <AccordionSummary 
        expandIcon={<ExpandIcon />}
        sx={[
          {
            minHeight: '20px',
            padding: 0,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              margin: '6px 0 6px 4px !important',
            },
            '& .MuiAccordionSummary-content .Mui-expanded': {
              margin: '6px 0 6px 4px !important',
            },
            '&.MuiAccordionSummary-root.Mui-expanded': {
              minHeight: '20px',
            },
          }
        ]}
        >
        <BodyMedium text={summary} color='#000000' />
      </AccordionSummary>
      <AccordionDetails sx={{
        padding: '6px 0 6px 24px'
      }}>
        {
          details.map((item) => 
            <Box sx={{display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
              <BodyMedium text={item.text} color='#757779' />
              {
                item.textLink && item.link ?
                <Link href={item.link} sx={{ textDecoration: 'none'}} target='_blank'>
                  <Box sx={[{ 
                  borderBottom: '1px dashed #1943E4', 
                  width: 'fit-content', 
                  display: 'inline-block',
                  }]}>
                    <BodyMedium text={item.textLink} color='#1943E4' />
                  </Box>
                </Link>
                : null
              }
            </Box>
          )
        }
        {
          links ? 
            links.map((item) => 
              <Link href={item.link} sx={{ textDecoration: 'none'}} target='_blank'>
                <Box sx={[{ 
                  borderBottom: '1px dashed #1943E4', 
                  width: 'fit-content', 
                  display: 'inline-block',
                  }]}>
                  <BodyMedium text={item.text} color='#1943E4' />
                </Box>
                {' '}
              </Link>
            )
          : null
        }
      </AccordionDetails>
    </Accordion>
  );
}
