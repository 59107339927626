import { Box } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { Deal } from '../../../../data/Deals';
import { dealReviewWithdrawData } from '../../../../data/states/DealReviewWithdrawData';
import { METAMASK_CONFIRMATION } from '../../../../data/states/States';
import { setActiveWithdrawState } from '../../../../features/activeState/activeWithdrawStateSlice';
import { FilledButton } from '../../../buttons/FilledButton';
import { OutlinedCard } from '../../../cards/OutlinedCard';
import { DescriptionButton } from '../../../DescriptionButton';
import { MoneyIcon, PigIcon } from '../../../Icons';
import { ResultBox } from '../../../ResultBox';
import { BodyLarge } from '../../../text/body/BodyLarge';
import { BodyMedium } from '../../../text/body/BodyMedium';

export function DealReviewWithdraw() {
  const dropdownMenuWithdrawValue: Deal = useSelector((state: RootState) => state.dropdownMenuWithdrawValue);
  const estimatedIncome = useSelector((state: RootState) => state.estimatedIncome.value);
  const receiveSum = useSelector((state: RootState) => state.receiveSum.value);

  const dispatch = useDispatch();

  const handleConfirmClick = () => {
    dispatch(setActiveWithdrawState(METAMASK_CONFIRMATION));
  }

  return (
    <OutlinedCard avatar={dealReviewWithdrawData.avatar} title={dealReviewWithdrawData.title}>
      <Box>
        <Box>
          <BodyLarge text='You are going to withdraw:' color='#000000' />
        </Box>
        <Box mt={2}>
          <ResultBox icon={dropdownMenuWithdrawValue.icon} helperText={'Coin and amount'}>
            <BodyMedium text={`${dropdownMenuWithdrawValue.name} ${dropdownMenuWithdrawValue.count}`} color='#000000' />
          </ResultBox>
        </Box>
        <Box mt={2}>
          <ResultBox icon={<PigIcon />} helperText={'Estimated income'}>
            <BodyMedium text={`${dropdownMenuWithdrawValue.name} ${estimatedIncome}`} color='#000000' />
          </ResultBox>
        </Box>
        <Box mt={2}>
          <ResultBox icon={<MoneyIcon />} helperText={'You will receive'}>
            <Box display={'inline-flex'}>
              <BodyMedium text={`${dropdownMenuWithdrawValue.name} ${receiveSum}`} color='#000000' />
            </Box>
          </ResultBox>
        </Box>
      </Box>
      <Box mt={7.5}>
        <FilledButton
          startIcon={dealReviewWithdrawData.buttonIcon}
          text={dealReviewWithdrawData.buttonText}
          onClick={handleConfirmClick}
           />
        {
        <Box mt={0.5} ml={0.25}>
          <DescriptionButton descriptionText={dealReviewWithdrawData.buttonDesc} />
        </Box>
        }
      </Box>
    </OutlinedCard>
  );
}
