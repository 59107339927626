import React from 'react';
import { headerData } from '../../data/HeaderData';
import { FilledButton } from '../buttons/FilledButton';
import { LogoIcon, MetamaskIcon } from '../Icons';
import { TextButton } from '../buttons/TextButton';
import styles from './header.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setAccount } from '../../features/account/accountSlice';
import { setActiveDepositState } from '../../features/activeState/activeDepositStateSlice';
import { BEFORE_YOU_START, DEAL, UNDERLYING_ASSET } from '../../data/states/States';
import { Box, Icon, Typography } from '@mui/material';
import { setAccountClick } from '../../features/account/accountClickSlice';
import Web3 from 'web3';
import { setActiveWithdrawState } from '../../features/activeState/activeWithdrawStateSlice';

export function Header() {
  const account = useSelector((state: RootState) => state.account.value);
  const isFirstStart = useSelector((state: RootState) => state.isFirstStart.value);

  const dispatch = useDispatch();

  const navList = headerData.navListButtonText.map((buttonText) => 
    <TextButton key={Math.random()} text={buttonText} />
  )

  const connectHandleClick = async () => {
    
    if ((window as any).ethereum) {
      if ((window as any).ethereum.networkVersion !== 1) {
        await (window as any).ethereum
        .request({method: 'wallet_switchEthereumChain', params: [{ chainId: Web3.utils.toHex(1) }],})
        .then((res: any) => {
          console.log(res);
          if (res == null) {
            (window as any).ethereum
            .request({method: 'eth_requestAccounts'})
            .then((res: string[]) => {
              dispatch(setAccountClick(true));
              dispatch(setAccount(res[0]));
              dispatch(setActiveDepositState(UNDERLYING_ASSET));
              dispatch(setActiveWithdrawState(DEAL));
            });
          }
        })
      }
      else {
        await (window as any).ethereum
        .request({method: 'eth_requestAccounts'})
        .then((res: string[]) => {
          dispatch(setAccountClick(true));
          dispatch(setAccount(res[0]));
          dispatch(setActiveDepositState(UNDERLYING_ASSET));
          dispatch(setActiveWithdrawState(DEAL));
        });
      }
    } else {
      alert("Please, install Metamask extension");
    }
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      checkAccountTest();
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const checkAccountTest = async () => {
    if ((window as any).ethereum) {
      await (window as any).ethereum
      .request({method: 'eth_accounts'})
      .then((res: string[]) => {
        console.log('Type of result of checkAccountTest = ', typeof(res));
        console.log('Result of checkAccountTest = ', res);
        
        if ((res.length == 0) || ((window as any).ethereum.networkVersion != 1)) {
          dispatch(setAccount(""));
        }
        else if ((res.length !== 0) && ((window as any).ethereum.networkVersion == 1)) {
          dispatch(setAccount(res[0]));
        }
      });
    } else {
      dispatch(setActiveDepositState(BEFORE_YOU_START));
      dispatch(setActiveWithdrawState(BEFORE_YOU_START));
    }
  }

  React.useEffect(() => {
    console.log('Account in deposit (Header): ', account);
    if (account == "" && isFirstStart == false) {
      dispatch(setActiveDepositState(BEFORE_YOU_START));
      dispatch(setActiveWithdrawState(BEFORE_YOU_START));
    } else if (account !== "") {
      dispatch(setActiveDepositState(UNDERLYING_ASSET));
      dispatch(setActiveWithdrawState(DEAL));
    }
  }, [account]);

  return (
    <header className={styles.Header}>
      <div className={styles.Logo}>
        <LogoIcon />
      </div>
      <div className={styles.NavList}>
        {navList}
      </div>
      <div className={styles.ConnectButton}>
        {
          account ? 
            <Box display={'flex'} gap={'8px'} alignItems={'center'}>
              <MetamaskIcon />
              <Typography color={'#2196F3'} fontSize={'14px'} lineHeight={'20px'} fontWeight={'700'}>
                {(account.slice(0, 5) + '...' + account.slice(account.length - 4, account.length)).toUpperCase()}
              </Typography>
            </Box>
          :
          <FilledButton 
            startIcon={<MetamaskIcon />}
            text={headerData.textButton}
            onClick={connectHandleClick}
          />
        }
      </div>
    </header>
  );
}
