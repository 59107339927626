
export interface State {
  name: string;
  isActive: boolean;
}

export const INITIAL_STATE: State = {
  name: "Initial state",
  isActive: true,
}

export const BEFORE_YOU_START: State = {
  name: "Before you start",
  isActive: false,
}

export const UNDERLYING_ASSET: State = {
  name: "Underlying asset",
  isActive: false,
}

export const METAMASK_APPROVAL: State = {
  name: "MetaMask approval",
  isActive: false,
}

export const AMOUNT: State = {
  name: "Amount",
  isActive: false,
}

export const FEES_OPTIMIZATION: State = {
  name: "Fees optimization",
  isActive: false,
}

export const DEAL_REVIEW: State = {
  name: "Deal review",
  isActive: false,
}

export const PROCESS_COMPLETED: State = {
  name: "Process completed",
  isActive: false,
}

export const DEAL: State = {
  name: "Deal",
  isActive: false,
}

export const METAMASK_CONFIRMATION: State = {
  name: "MetaMask confirmation",
  isActive: false,
}