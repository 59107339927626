import React from "react";

export function EthIcon() {
  return (
    <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.36466 0L7.2038 0.546763V16.4125L7.36466 16.5731L14.7294 12.2199L7.36466 0Z" fill="#343434"/>
    <path d="M7.36472 0L0 12.2199L7.36472 16.5732V8.8724V0Z" fill="#8C8C8C"/>
    <path d="M7.3647 17.9676L7.27405 18.0781V23.7298L7.3647 23.9946L14.7338 13.6165L7.3647 17.9676Z" fill="#3C3C3B"/>
    <path d="M7.36472 23.9945V17.9675L0 13.6164L7.36472 23.9945Z" fill="#8C8C8C"/>
    <path d="M7.36462 16.5731L14.7292 12.2199L7.36462 8.87247V16.5731Z" fill="#141414"/>
    <path d="M6.10352e-05 12.2199L7.36467 16.5731V8.87247L6.10352e-05 12.2199Z" fill="#393939"/>
    </svg>
  );
}