export const feesOptimizationFAQData = {
  list: [
    {
      summary: 'Why, when using the "Fees optimization" option, funds are placed within 24 hours?',
      details: [
        {
          text: 'The DSF application accumulates small deposits within 24 hours, selects the most appropriate time and value for the transaction and executes the transaction.',
        },
        {
          text: 'If you want to place funds immediately, then you need to turn off this option. If you disable the "Fees optimization" option, the funds will be deposited immediately and will immediately start generating income for you.'
        }
      ]
    },
    {
      summary: 'Why the commission is way less when using the "Fees optimization" option?',
      details: [
        {
          text: 'With this option, your funds are sent to a DSF smart contract and awaiting placement.',
        },
        {
          text: 'In this case you pay a commission as for a usual transfer, and the payer of the main commission is DSF, making one transfer for all clients of that day, and the commission is taken from the fact of the transfer and does not depend on the amount of transfer.',
        }
      ]
    },
    {
      summary: 'If you use the "Fees optimization" option, can you pick up my funds?',
      details: [
        {
          text: 'No, your funds are on a smart contract and we don\'t have access to them, just like any outsider.',
        },
        {
          text: 'We can only place them in the strategy by fixed rules or return them back to you. A withdrawal request for funds waiting to be placed will be implemented shortly.'
        }
      ]
    },
  ]
}