import { Box } from '@mui/material';
import React from 'react';
import { BodySmall } from '../../text/body/BodySmall';

export interface IEstimatedFeeProps {
  text: string;
  fee: string;
  disabled?: boolean;
  error?: boolean;
}

export function EstimatedFee({ text, fee, disabled = false, error = false }: IEstimatedFeeProps) {
  return (
    <Box width={'fit-content'} sx={[
      {
        background: disabled ? '#9E9EA2' : error ? 'radial-gradient(362.5% 2610.13% at 60.15% 117.5%, #DC362E 0%, rgba(97, 97, 101, 0) 50%) top right/200% 200%' : 'radial-gradient(362.5% 2610.13% at 60.15% 117.5%, #1F4CF0 0%, rgba(97, 97, 101, 0) 50%) top right/200% 200%',
        borderRadius: '4px',
        padding: '2px 8px',
        animation: 'gradient 1s alternate infinite linear',
        '@keyframes gradient': {
          'from': {
            backgroundPosition: 'left top',
            backgroundSize: '200% 100%',
          },
          '49.9%': {
            backgroundPosition: 'left top',
          },
          '50%': {
            backgroundSize: '100% 100%',
          },
          '50.1%': {
            backgroundPosition: 'right top',
          },
          'to': {
            backgroundPosition: 'right top',
            backgroundSize: '200% 100%',
          },
        }
      }
    ]}>
      <BodySmall text={`${text}: $${fee}`} color={'#ffffff'} />
    </Box>
  );
}
