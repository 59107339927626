import { Typography } from '@mui/material';
import React from 'react';
import { ITextProps } from '../../ItextProps';

export function TitleLarge({ text, color }: ITextProps) {
  return (
    <Typography color={color} sx={{
      fontSize: '22px',
      lineHeight: '28px',
    }}>
      {text}
    </Typography>
  );
}
