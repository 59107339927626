import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Coin } from '../../data/Coins';

export const dropdownMenuValueSlice = createSlice({
  name: 'dropdownMenuValue',
  initialState: {
    icon: null,
    name: ""
  },
  reducers: {
    setDropdownMenuValue: (state: any, action: PayloadAction<Coin>) => {
      state.icon = action.payload.icon;
      state.name = action.payload.name;
    }
  }
});

export const { setDropdownMenuValue } = dropdownMenuValueSlice.actions;

export default dropdownMenuValueSlice.reducer;