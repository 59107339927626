import { Alert } from '@mui/material';
import React from 'react';
import { ErrorCircleOutlineIcon } from '../../Icons';
import { LabelSmall } from '../../text/label/LabelSmall';
import { IAlertProps } from '../SuccessAlert';

export function ErrorAlert({ text }: IAlertProps) {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(false);
    return () => {
      setIsLoading(true);
      setTimeout(() => {}, 300);
    }
  }, []);

  return (
    <Alert
      icon={<ErrorCircleOutlineIcon />}
      severity="error"
      sx={[
        {
          '&': {
            width: '100%',
            backgroundColor: '#F9DEDC',
            borderRadius: '8px',
            padding: '8px',
            transition: 'opacity .3s ease-in-out',
            opacity: isLoading ? '0' : '1',
          }
        },
      ]}
    >
      <LabelSmall text={text} color={'#410E0B'} />
    </Alert>
  );
}
