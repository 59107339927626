import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'inline-flex',
    gap: '5px',
    flexWrap: 'wrap',
    backgroundColor: '#E0E2E4',
    color: '#000000',
    boxShadow: '0px 10px 40px rgba(225, 230, 235, 0.4)',
    maxWidth: '196px',
    padding: ' 15px 12px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#E0E2E4',
  }
}));
