import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const approvalDAISlice = createSlice({
  name: 'approvalDAI',
  initialState: {
    value: false,
  },
  reducers: {
    setApprovalDAI: (state: any, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    }
  }
});

export const { setApprovalDAI } = approvalDAISlice.actions;

export default approvalDAISlice.reducer;