import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const approveClickSlice = createSlice({
  name: 'checkedMaxWalletBalanceClick',
  initialState: {
    value: false,
  },
  reducers: {
    setApproveClick: (state: any, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    }
  }
});

export const { setApproveClick } = approveClickSlice.actions;

export default approveClickSlice.reducer;