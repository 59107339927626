import React from 'react';
import { Box, Grid } from '@mui/material';
import { ProgressCard } from '../cards/ProgressCard';
import { Coin } from '../../data/Coins';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { BeforeYouStart } from '../states/BeforeYouStart';
import { UnderlyingAsset } from '../states/deposit/UnderlyingAsset';
import { AMOUNT, BEFORE_YOU_START, DEAL_REVIEW, FEES_OPTIMIZATION, INITIAL_STATE, METAMASK_APPROVAL, PROCESS_COMPLETED, UNDERLYING_ASSET } from '../../data/states/States';
import { setActiveDepositState } from '../../features/activeState/activeDepositStateSlice';
import { MetaMaskApproval } from '../states/deposit/MetaMaskApproval';
import { Amount } from '../states/deposit/Amount';
import { FeesOptimization } from '../states/deposit/FeesOptimization';
import { DealReview } from '../states/deposit/DealReview';
import { ProcessCompleted } from '../states/ProcessCompleted';
import { setApprovalUSDT } from '../../features/approve/approvalUSDTSlice';
import { setApprovalUSDC } from '../../features/approve/approvalUSDCSlice';
import { setApprovalDAI } from '../../features/approve/approvalDAISlice';
import { setApproval } from '../../features/approve/approvalSlice';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import { contractAddressUsdt, abiUsdt } from '../../data/smartcontracts/SmartContractUsdtData';
import { contractAddressDai, abiDai } from '../../data/smartcontracts/SmartContractDaiData';
import { contractAddressUsdc, abiUsdc } from '../../data/smartcontracts/SmartContractUsdcData';
import { contractAddressDsf } from '../../data/smartcontracts/SmartContractData';
import { SuccessAlert } from '../alerts/SuccessAlert';
import { InfoAlert } from '../alerts/InfoAlert';
import { InitialState } from '../states/InitialState';
import { ContactUs } from '../contacts/ContactUs';
import { CustomCircularProgress } from '../CustomCircularProgress';
import { FAQ } from '../faq-section/FAQ';
import { setIsApprove } from '../../features/approve/isApproveSlice';
import { beforeYouStartFAQData } from '../../data/faq/BeforeYouStartFAQData';
import { underlyingAssetFAQData } from '../../data/faq/UnderlyingAssetFAQData';
import { amountFAQData } from '../../data/faq/AmountFAQData';
import { setAccountClick } from '../../features/account/accountClickSlice';
import { feesOptimizationFAQData } from '../../data/faq/FeesOptimizationFAQData';
import { ErrorAlert } from '../alerts/ErrorAlert';

export function Deposit() {
  const dropdownMenuValue: Coin = useSelector((state: RootState) => state.dropdownMenuValue);
  const account = useSelector((state: RootState) => state.account.value);
  const accountClick = useSelector((state: RootState) => state.accountClick.value);
  const approveClick = useSelector((state: RootState) => state.approveClick.value);
  const activeDepositState = useSelector((state: RootState) => state.activeDepositState);
  const approval = useSelector((state: RootState) => state.approval.value);
  const showLoader = useSelector((state: RootState) => state.showLoader.value);
  const estimatedFeeError = useSelector((state: RootState) => state.estimatedFeeError.value);

  const dispatch = useDispatch();

  // const [isApprove, setIsApprove] = React.useState(false);

  React.useEffect(() => {
    console.log("It's alpha version of DSF");
    
    if (account) {
      dispatch(setActiveDepositState(UNDERLYING_ASSET));
    }
  }, []);

  async function getAccounts() {
    try {
      return await (window as any).ethereum.request({ method: "eth_requestAccounts" });
    } catch (error) {
      console.log(error);
    }
  }

  const checkApproveUsdt = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiUsdt as AbiItem[], contractAddressUsdt);
      const fromAddress = (await getAccounts())[0];

      try {
        await contract.methods.allowed(fromAddress, contractAddressDsf).call({from: fromAddress})
        .then(function(result : any){
          console.log(result.length);
          if (result.length >= 24) // >=25!!
          {
            dispatch(setApprovalUSDT(true));
            dispatch(setApproval(true));
            dispatch(setIsApprove(true));
          } else {
            dispatch(setApproval(false));
            dispatch(setIsApprove(false));
          }
      });
      } catch (error) {
          dispatch(setApproval(false));
          console.log(error);
      }
    }
  }

  const checkApproveUsdc = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiUsdc as AbiItem[], contractAddressUsdc);
      const fromAddress = (await getAccounts())[0];

      try {
        await contract.methods.allowance(fromAddress, contractAddressDsf).call({from: fromAddress})
        .then(function(result : any){
          console.log(result.length);
          if (result.length >= 25) // >=25!!
          {
            dispatch(setApprovalUSDC(true));
            dispatch(setApproval(true));
            dispatch(setIsApprove(true));
          } else {
            dispatch(setApproval(false));
            dispatch(setIsApprove(false));
          }
          
      });
      } catch (error) {
        dispatch(setApproval(false));
        console.log(error)
      }
    }
  }

  const checkApproveDai = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiDai as AbiItem[], contractAddressDai);
      const fromAddress = (await getAccounts())[0];

      try {
        await contract.methods.allowance(fromAddress, contractAddressDsf).call({from: fromAddress})
        .then(function(result : any){
          console.log(result.length);
          if (result.length >= 25) // >=25!!
          {
            dispatch(setApprovalDAI(true));
            dispatch(setApproval(true));
            dispatch(setIsApprove(true));
          } else {
            dispatch(setApproval(false));
            dispatch(setIsApprove(false));
          }
          
      });
      } catch (error) {
        dispatch(setApproval(false));
        console.log(error)
      }
    }
  }

  React.useEffect(() => {
    if (dropdownMenuValue.name === "USDT")
    {
      checkApproveUsdt();
    }
    if (dropdownMenuValue.name === "DAI")
    {
      checkApproveDai();
    }
    if (dropdownMenuValue.name === "USDC")
    {
      checkApproveUsdc();
    }
  }, [dropdownMenuValue.name]);

  // connection alert
  const [connectionAlert, setConnectionAlert] = React.useState(false);

  React.useEffect(() => {
    if (account) {
      setConnectionAlert(true);
    }
    setTimeout(() => setConnectionAlert(false), 6000);
  }, [account]);

  // approving alert
  const [approveTrueAlert, setApproveTrueAlert] = React.useState(false);

  React.useEffect(() => {
    if (approval) {
      setApproveTrueAlert(true);
    }
    setTimeout(() => setApproveTrueAlert(false), 6000);
  }, [approval]);

  return (
    <Box>
      <Grid container spacing={3} position={'relative'}>
        {
          showLoader ?
            <Box sx={[
              {
                position: 'absolute',
                zIndex: 2,
                background: 'rgba(255, 255, 255, 0.5)',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }
            ]}>
              <CustomCircularProgress />
            </Box>
          : null
        }
        
        <Grid item xs={8}>
          {
            activeDepositState.name === INITIAL_STATE.name ? <InitialState /> :
            activeDepositState.name === BEFORE_YOU_START.name ? <BeforeYouStart /> :
            activeDepositState.name === UNDERLYING_ASSET.name ? <UnderlyingAsset /> :
            activeDepositState.name === METAMASK_APPROVAL.name ? <MetaMaskApproval /> :
            activeDepositState.name === AMOUNT.name ? <Amount /> :
            activeDepositState.name === FEES_OPTIMIZATION.name ? <FeesOptimization /> :
            activeDepositState.name === DEAL_REVIEW.name ? <DealReview /> :
            activeDepositState.name === PROCESS_COMPLETED.name ? <ProcessCompleted isDeposit /> :
            null
          }
        </Grid>
        <Grid item xs={4}>
          <ProgressCard />
        </Grid>
        {
          activeDepositState.name === UNDERLYING_ASSET.name && connectionAlert && accountClick ?
            <>
              <Grid item xs={4} sx={{ minHeight: '72px' }}>
                <SuccessAlert text='Your wallet has been connected' />
              </Grid>
              <Grid item xs={8}>
              </Grid>
            </>  :
          activeDepositState.name === UNDERLYING_ASSET.name && !approval && estimatedFeeError ?
          <>
            <Grid item xs={6} sx={{ minHeight: '72px' }}>
              <ErrorAlert text='Not sufficient ETH on MetaMask. Please increase your wallet balance to proceed' />
            </Grid>
            <Grid item xs={6}>
            </Grid>
          </>  :
          activeDepositState.name === METAMASK_APPROVAL.name ?
            <>
              <Grid item xs={4} sx={{ minHeight: '72px' }}> 
                <InfoAlert text='You are being redirected to MetaMask...' />
              </Grid>
              <Grid item xs={8}>
              </Grid>
            </> :
          activeDepositState.name === AMOUNT.name && approveTrueAlert && approveClick ? 
            <>
              <Grid item xs={4} sx={{ minHeight: '72px' }}> 
                <SuccessAlert text='Your underlying asset is approved' /> 
              </Grid>
              <Grid item xs={8}>
              </Grid>
            </> :
          activeDepositState.name === FEES_OPTIMIZATION.name && estimatedFeeError ?
          <>
            <Grid item xs={6} sx={{ minHeight: '72px' }}> 
              <ErrorAlert text='Not sufficient ETH on MetaMask. Please increase your wallet balance to proceed' />
            </Grid>
            <Grid item xs={6}>
            </Grid>
          </> :
          null
        }
        <Grid item xs={8}>
          {
            activeDepositState.name === BEFORE_YOU_START.name ?
              <Box>
                <FAQ list={beforeYouStartFAQData.list} />
              </Box> :
            activeDepositState.name === UNDERLYING_ASSET.name ?
              <Box>
                <FAQ list={underlyingAssetFAQData.list} />
              </Box> :
            activeDepositState.name === AMOUNT.name ?
              <Box>
                <FAQ list={amountFAQData.list} />
              </Box> :
            activeDepositState.name === FEES_OPTIMIZATION.name ?
              <Box>
                <FAQ list={feesOptimizationFAQData.list} />
              </Box> :
            null
          }
        </Grid>
        <Grid item xs={4} zIndex={2}>
          <Box display={'flex'} justifyContent={'flex-end'} mr={2}>
            <ContactUs />
          </Box>  
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid> 
      
    </Box>
  );
}
