import React from 'react';
import styles from './switchwithicon.module.css';
import { Box, Switch, Typography } from '@mui/material';
import { theme } from '../../theme';
import { CheckedIcon, UncheckedIcon } from '../Icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setSwitchFeesOptimization } from '../../features/switchFeesOptimizationSlice';
import { BodyLarge } from '../text/body/BodyLarge';

export function SwitchWithIcon() {
  const switchFeesOptimization = useSelector((state: RootState) => state.switchFeesOptimization.value);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSwitchFeesOptimization(event.target.checked));
  };

  return (
    <Box display={'flex'} gap={'12px'} alignItems={'center'}>
      <Switch 
        value={switchFeesOptimization}
        defaultChecked
        onChange={handleChange}
        checkedIcon={<CheckedIcon />} 
        icon={<UncheckedIcon />} 
        disableRipple 
        sx={[
        {
          width: 52,
          height: 32,
          padding: 0,
          borderRadius: 35,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: '4px',
            transitionDuration: '300ms',
            '&.Mui-checked': {
              color: '#ffffff',
              '& + .MuiSwitch-track': {
                backgroundColor: '#2196F3',
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color:
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 24,
            height: 24,
          },
          '& .MuiSwitch-track': {
            border: '2px solid #9E9EA2',
            borderRadius: 26 / 2,
            backgroundColor: '#EEEEF2',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
          },
        }
      ]}/>
      <BodyLarge text='Optimize fees' color='#000000' />
    </Box>

  );
}
