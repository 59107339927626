import { Button } from '@mui/material';
import React from 'react';
import { IButtonProps } from '../FilledButton';

export function TextButton({ text, startIcon, startIconDisabled, disabled = false, onClick }: IButtonProps) {
  return (
    <Button 
      color="primary" 
      variant="text" 
      disabled={disabled}
      startIcon={!disabled ? startIcon : startIconDisabled} 
      disableRipple
      onClick={onClick}
      sx={[
      {
        padding: '10px 12px',
        borderRadius: '100px',
      },
      {
        '&:hover': {
          backgroundColor: '#2196F314',
        }
      },
      {
        '&:focus': {
          backgroundColor: '#2196F31F',
        }
      },
      {
        '&:active': {
          backgroundColor: '#BBDEFB',
        }
      }
    ]}>
      {text}
    </Button>
  );
}
