import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const approvalSlice = createSlice({
  name: 'approval',
  initialState: {
    value: false,
  },
  reducers: {
    setApproval: (state: any, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    }
  }
});

export const { setApproval } = approvalSlice.actions;

export default approvalSlice.reducer;