import { Typography } from '@mui/material';
import React from 'react';
import { ITextProps } from '../../ItextProps';

export function BodySmall({ text, color }: ITextProps) {
  return (
    <Typography display={'inline'} color={color} sx={{
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    }}>
      {text}
    </Typography>
  );
}
