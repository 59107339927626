import { Box, CircularProgress, circularProgressClasses, CircularProgressProps } from '@mui/material';
import React from 'react';

export function CustomCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#50ACF5',
        }}
        size={60}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        // disableShrink
        sx={{
          color: '#1967D2',
          animationDuration: '900ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
            // strokeDasharray: '9px, 200px',
          },
        }}
        size={60}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
