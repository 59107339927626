import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    value: "",
  },
  reducers: {
    setAccount: (state: any, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { setAccount } = accountSlice.actions;

export default accountSlice.reducer;