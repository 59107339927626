import { Box } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { BEFORE_YOU_START, DEAL, UNDERLYING_ASSET } from '../../../data/states/States';
import { setAccount } from '../../../features/account/accountSlice';
import { setActiveDepositState } from '../../../features/activeState/activeDepositStateSlice';
import { setActiveWithdrawState } from '../../../features/activeState/activeWithdrawStateSlice';
import { setIsFirstStart } from '../../../features/isFirstStartSlice';

export function InitialState() {
  const account = useSelector((state: RootState) => state.account.value);
  
  const dispatch = useDispatch();

  React.useEffect(() => {

    console.log('initial state is mounting');

    const checkAccount = async () => {
      if ((window as any).ethereum) {

        if ((window as any).ethereum.networkVersion !== 1)

        console.log('checking account fn is calling');

        try {
          console.log('Check connecting to the chain', (window as any).ethereum.isConnected());
          await (window as any).ethereum
          .request({method: 'eth_accounts'})
          .then((res: string[]) => {
            if ((res.length !== 0) && (res[0] !== null) && ((window as any).ethereum.networkVersion == 1)) {
              dispatch(setAccount(res[0]));
              dispatch(setActiveDepositState(UNDERLYING_ASSET));
              dispatch(setActiveWithdrawState(DEAL));
              
              console.log('account verification passed:', res[0]);
              console.log('check account value after verification: ', account);
            } else {
              
              dispatch(setActiveDepositState(BEFORE_YOU_START));
              dispatch(setActiveWithdrawState(BEFORE_YOU_START));
            
              console.log('account not found');
            }
            dispatch(setIsFirstStart(false));
          });
        }
        catch {
          dispatch(setActiveDepositState(BEFORE_YOU_START));
          dispatch(setActiveWithdrawState(BEFORE_YOU_START));

          console.log('account verification failed');
        }
      } else {
        dispatch(setActiveDepositState(BEFORE_YOU_START));
        dispatch(setActiveWithdrawState(BEFORE_YOU_START));
      }
    };
    checkAccount();
  }, [])
  
  return (
    <Box></Box>
  );
}
