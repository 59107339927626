import { Box, Typography } from '@mui/material';
import React from 'react';
import { estimatedIncomeData } from '../../../data/estimatedComponents/EstimatedIncomeData';
import { LightTooltip } from '../../LightTooltip';
import { BodySmall } from '../../text/body/BodySmall';

export interface IEstimatedIncomeProps {
  income: string;
  disabled?: boolean;
}

export function EstimatedIncome({ income, disabled = false }: IEstimatedIncomeProps) {

  return (
      <Box>
        <LightTooltip title={<Box>
          <BodySmall text={estimatedIncomeData.tooltipText[0]} color='#000000' />
          <BodySmall text={estimatedIncomeData.tooltipText[1]} color='#1F4CF0' />
          <BodySmall text={estimatedIncomeData.tooltipText[2]} color='#000000' />
      </Box>} arrow placement="top">
          <Box width={'fit-content'} sx={{ cursor: 'pointer', }}>
            <Typography 
              color={disabled ? '#9E9EA2' : '#1943E4'}
              sx={{ borderBottom: disabled ? '1px dashed #424446' : '1px dashed #1943E4' }}>
                {estimatedIncomeData.title}
            </Typography>
          </Box>
        </LightTooltip>
        <Box mt={1}>
          <Typography color={disabled ? '#BDBFC1' : '#000000'}>
            {income}
          </Typography>
        </Box>
      </Box>
  );
}
