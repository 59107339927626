import { Button } from '@mui/material';
import React from 'react';
import { IButtonProps } from '../FilledButton';

export function TonalButton({ text, startIcon, startIconDisabled, disabled = false, onClick }: IButtonProps) {
  return (
    <Button 
      color="primary" 
      variant="contained" 
      disabled={disabled}
      startIcon={!disabled ? startIcon : startIconDisabled} 
      disableRipple
      onClick={onClick}
      sx={[
      {
        padding: '10px 24px',
        borderRadius: '100px',
        backgroundColor: '#BBDEFB',
        color: '#212324',
        boxShadow: 'none',
      },
      {
        '&:hover': {
          backgroundColor: '#AFCFEA',
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        }
      },
      {
        '&:focus': {
          backgroundColor: '#AFCFEA',
          boxShadow: 'none',
        }
      },
      {
        '&:active': {
          backgroundColor: '#AFCFEA',
          boxShadow: 'none',
        }
      }
    ]}>
      {text}
    </Button>
  );
}
