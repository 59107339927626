import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const approveFeeSlice = createSlice({
  name: 'approveFee',
  initialState: {
    value: "",
  },
  reducers: {
    setApproveFee: (state: any, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { setApproveFee } = approveFeeSlice.actions;

export default approveFeeSlice.reducer;