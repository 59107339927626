import { DAIIcon, USDCIcon, USDTIcon } from "../components/Icons";

export interface Deal {
  icon?: React.ReactNode,
  name?: string;
  count?: string;
  date?: string;
}

export const DAIDeal: Deal = {
  icon: DAIIcon(),
  name: "DAI",
  count: "1.99",
}

export const USDTDeal: Deal = {
  icon: USDTIcon(),
  name: "USDT",
  count: "6.94",
}

export const USDCDeal: Deal = {
  icon: USDCIcon(),
  name: "USDC",
  count: "2.22",
}