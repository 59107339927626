import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const textFieldValueSlice = createSlice({
  name: 'textFieldValue',
  initialState: {
    value: "",
  },
  reducers: {
    setTextFieldValue: (state: any, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { setTextFieldValue } = textFieldValueSlice.actions;

export default textFieldValueSlice.reducer;