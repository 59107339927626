import { Icon, Link } from '@mui/material';
import React from 'react';

export interface IContactLinkProps {
  link: string;
  icon: React.ReactNode;
}

export function ContactLink({ link, icon }: IContactLinkProps) {
  return (
    <Link href={link} target={'_blank'} sx={
      { 
        textDecoration: 'none', 
        cursor: 'pointer',
        borderRadius: '100px',
      }
      }>
        <Icon sx={{
          width: '32px',
          height: '32px',
          borderRadius: '100px',
          '&:hover': {
            background: '#E5EEF5',
          }
        }}>
          {icon}
        </Icon>
    </Link>
  );
}
