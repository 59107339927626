import React from "react";

export function USDTIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_302_1495)">
    <path d="M18.754 10.518C18.754 11.143 16.516 11.666 13.516 11.798L13.518 11.799C13.0185 11.826 12.5182 11.837 12.018 11.832C11.239 11.832 10.688 11.809 10.494 11.799C7.488 11.667 5.244 11.144 5.244 10.518C5.244 9.892 7.488 9.368 10.494 9.234V11.278C10.69 11.293 11.253 11.326 12.031 11.326C12.964 11.326 13.431 11.286 13.516 11.279V9.236C16.516 9.369 18.754 9.893 18.754 10.518ZM23.944 11.064L12.124 22.39C12.0905 22.4221 12.0459 22.44 11.9995 22.44C11.9531 22.44 11.9085 22.4221 11.875 22.39L0.0559999 11.064C0.028264 11.0379 0.0096081 11.0037 0.00276911 10.9663C-0.00406988 10.9288 0.00126809 10.8902 0.0179999 10.856L4.394 1.663C4.40845 1.6321 4.43152 1.60603 4.46044 1.58794C4.48936 1.56984 4.52289 1.56049 4.557 1.561H19.445C19.4787 1.56115 19.5117 1.57093 19.54 1.58917C19.5684 1.60742 19.5909 1.63338 19.605 1.664L23.982 10.856C23.9987 10.8902 24.0041 10.9288 23.9972 10.9663C23.9904 11.0037 23.9717 11.0379 23.944 11.064V11.064ZM19.466 10.66C19.466 9.854 16.914 9.18 13.519 9.023V7.195H17.705V4.405H6.308V7.195H10.493V9.024C7.091 9.18 4.533 9.854 4.533 10.661C4.533 11.469 7.091 12.141 10.493 12.299V18.161H13.518V12.297C16.912 12.14 19.466 11.467 19.466 10.66Z" fill="#50AF95"/>
    </g>
    <defs>
    <clipPath id="clip0_302_1495">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
}