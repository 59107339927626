import { Avatar, Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';
import { State } from '../../../data/states/States';
import { ProcessCompletedIcon } from '../../Icons/ProcessCompletedIcon';

export interface ICardProps {
  avatar?: string;
  title?: string;
  list?: Array<State>;
  children?: React.ReactNode;
  end?: boolean;
}

export function OutlinedCard({ avatar, title, children, end = false }: ICardProps) {
  return (
    <Card sx={[
      {
        position: 'relative',
        borderRadius: '12px',
        border: '2px solid #D9EDFD',
        boxShadow: 'none',
        backgroundColor: '#F5F5F9',
        height: '100%'
      }
    ]}>
      <CardHeader 
        title={title}
        avatar={
          <Avatar sx={{ bgcolor: '#2196F3' }}>{end ?  <ProcessCompletedIcon /> : avatar}</Avatar>
        }
        sx={[
        {
          backgroundColor: '#ffffff',
        },
        {
          '.MuiCardHeader-title': {
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.1px',
            color: '#1C1B1F',
          }
        }
      ]}>
      </CardHeader>
      <CardContent sx={{
          paddingTop: '24px',
          paddingBottom: '48px !important',
      }}>
        {children}
      </CardContent>
    </Card>
  );
}
