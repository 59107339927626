import { IContactLinkProps } from "../components/contacts/ContactLink";
import { TwitterIcon } from "../components/Icons";
import { MailIcon } from "../components/Icons/MailIcon";
import { TelegramIcon } from "../components/Icons/TelegramIcon";

export const contactsData: Array<IContactLinkProps> = [
  {
    link: 'https://t.me/DSF_Finance',
    icon: TelegramIcon(),
  },
  {
    link: 'https://twitter.com/DsfFinance',
    icon: TwitterIcon(),
  },
  {
    link: 'mailto:godefi@dsf.finance',
    icon: MailIcon(),
  },
]