import { Typography } from '@mui/material';
import React from 'react';
import { ITextProps } from '../../ItextProps';

export function BodyMedium({ text, color }: ITextProps) {
  return (
    <Typography color={color} sx={{
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    }}>
      {text}
    </Typography>
  );
}
