export const underlyingAssetFAQData = {
  list: [
    {
      summary: 'What is Approval?',
      details: [
        {
          text: 'This is a feature that allows you to approve DSF to use your selected asset. Approval occurs the first time you use DSF, but every time you select a new asset.',
        }
      ]
    },
    {
      summary: 'What is Estimated Fee?',
      details: [
        {
          text: 'This is the estimated value of the commission the Ethereum network charges for processing this transaction.  For your convenience, the fee is shown in dollars, but it is actually charged in ETH coin. Therefore, in order to make transactions on the Ethereum network, you must have ETH coin in your wallet.',
        },
      ]
    },
    {
      summary: 'Why Ethereum charges fees?',
      details: [
        {
          text: 'These are fees for completing a task that are received by network validators, or as they used to be called, miners.',
        },
      ]
    },
    {
      summary: 'How the interest rate is calculated?',
      details: [
        {
          text: '(Commission income / total volume of blocked funds in the exchange pool) * 100 ))',
        },
        {
          text: 'Commission income: ( amount of earned token CRV * its average daily value) + (amount of earned token CVX * its average daily value)',
        },
        {
          text: 'The total amount of blocked funds: funds contributed to the exchange pool',
          link: 'https://curve.fi/#/ethereum/pools/mim/deposit',
          textLink: 'Curve MIM + 3Crv pool',
        },
        {
          text: 'The rate is inclusive of DSF commission and is updated several times a day.',
        },
        {
          text: 'DSF commission is 15% of the actual income, charged at the moment when the income is taken by the user ',
        },
      ]
    },
  ]
}