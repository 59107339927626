import React from "react";

export function MetamaskGrayIcon() {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_285_341)">
    <path d="M14.5872 0L8.17944 4.74127L9.37102 1.94711L14.5872 0Z" fill="#929292"/>
    <path d="M0.361155 0.00559998L5.56338 1.94746L6.69481 4.77826L0.361155 0.00559998ZM12.0085 10.0834L14.8407 10.1373L13.8509 13.4997L10.395 12.5483L12.0085 10.0834ZM2.92479 10.0834L4.53228 12.5483L1.08227 13.4998L0.0984802 10.1373L2.92479 10.0834Z" fill="#939393"/>
    <path d="M6.54096 4.0572L6.6567 7.79537L3.19339 7.63781L4.17852 6.1516L4.19101 6.1373L6.54096 4.0572ZM8.35647 4.01549L10.7423 6.13742L10.7547 6.15165L11.7398 7.63787L8.27726 7.79537L8.35647 4.01549ZM4.6337 10.0942L6.52475 11.5677L4.32803 12.6282L4.6337 10.0942ZM10.3 10.094L10.5993 12.6283L8.40868 11.5676L10.3 10.094Z" fill="#939393"/>
    <path d="M8.45706 11.4288L10.68 12.5052L8.61222 13.4878L8.63369 12.8384L8.45706 11.4288ZM6.47565 11.4293L6.30596 12.8277L6.3199 13.4871L4.24731 12.5052L6.47565 11.4293Z" fill="#C5C5C5"/>
    <path d="M5.83374 8.28328L6.41463 9.50408L4.43695 8.92471L5.83374 8.28328ZM9.09953 8.2834L10.5029 8.92471L8.51877 9.5039L9.09953 8.2834Z" fill="#333333"/>
    <path d="M4.78486 10.0818L4.46519 12.7091L2.75177 10.1392L4.78486 10.0818ZM10.1486 10.0818L12.1818 10.1392L10.4619 12.7092L10.1486 10.0818ZM11.7899 7.48924L10.3103 8.99722L9.16943 8.47589L8.62325 9.62413L8.2652 7.6496L11.7899 7.48924ZM3.14272 7.48924L6.66809 7.6496L6.30999 9.62413L5.76369 8.47607L4.62894 8.99728L3.14272 7.48924Z" fill="#7F7F7F"/>
    <path d="M3.04303 7.17978L4.71708 8.8785L4.77506 10.5555L3.04303 7.17978ZM11.892 7.17674L10.1568 10.5585L10.2222 8.8785L11.892 7.17674ZM6.58625 7.28326L6.65363 7.70734L6.82011 8.76382L6.71307 12.0086L6.20703 9.40198L6.20685 9.37503L6.58625 7.28326ZM8.34623 7.27737L8.72662 9.37503L8.72645 9.40198L8.21912 12.0151L8.19905 11.3615L8.1199 8.74457L8.34623 7.27737Z" fill="#939393"/>
    <path d="M10.371 8.81102L10.3143 10.2681L8.54833 11.644L8.19133 11.3917L8.5915 9.33054L10.371 8.81102ZM4.56848 8.81102L6.34176 9.33054L6.74192 11.3917L6.38492 11.644L4.61888 10.268L4.56848 8.81102Z" fill="#A2A2A2"/>
    <path d="M3.90939 12.1834L6.16876 13.2539L6.15919 12.7968L6.34825 12.6308H8.5844L8.78028 12.7962L8.76582 13.253L11.0109 12.1861L9.91843 13.0888L8.59747 13.9961H6.33011L5.01003 13.0851L3.90939 12.1834Z" fill="#B0B0B0"/>
    <path d="M8.29523 11.2863L8.61466 11.512L8.80185 13.0055L8.53095 12.7768H6.40319L6.13742 13.0101L6.31849 11.5121L6.63804 11.2863H8.29523Z" fill="#161616"/>
    <path d="M14.1641 0.131256L14.9333 2.43875L14.453 4.77191L14.795 5.03575L14.3322 5.3889L14.6801 5.65758L14.2194 6.07706L14.5022 6.28187L13.7517 7.15838L10.6734 6.26209L10.6468 6.2478L8.42852 4.37658L14.1641 0.131256ZM0.769183 0.131256L6.50487 4.37658L4.28657 6.2478L4.25991 6.26209L1.18166 7.15838L0.431142 6.28187L0.713708 6.07723L0.253342 5.65758L0.600542 5.38919L0.130725 5.03505L0.485683 4.77103L0 2.43886L0.769183 0.131256Z" fill="#4C4C4C"/>
    <path d="M10.5229 6.06608L13.7845 7.01569L14.8441 10.2815H12.0485L10.1224 10.3058L11.5232 7.57534L10.5229 6.06608ZM4.41048 6.06608L3.41 7.57534L4.811 10.3058L2.8857 10.2815H0.0951538L1.14883 7.01575L4.41048 6.06608ZM9.53075 1.93153L8.61847 4.39548L8.42486 7.72397L8.35078 8.76727L8.34489 11.4324H6.58841L6.5827 8.77228L6.50838 7.7231L6.31471 4.39548L5.40255 1.93153H9.53075Z" fill="#A2A2A2"/>
    </g>
    <defs>
    <clipPath id="clip0_285_341">
    <rect width="14.9333" height="14" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
}