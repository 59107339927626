import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { Deal } from '../../../data/Deals';
import { setDropdownMenuWithdrawValue } from '../../../features/selects/dropdownMenuWithdrawValue';

export interface IDropdownMenuProps {
  labelName: string;
  menuItemsWithIcon: Array<Deal>;
  disabled?: boolean;
}

export function DropdownMenuWithdraw({ labelName, menuItemsWithIcon = [], disabled = false }: IDropdownMenuProps) {
  const [isSelected, setIsSelected] = React.useState(true);
  let choice = false;
  const dropdownMenuWithdrawValue: Deal = useSelector((state: RootState) => state.dropdownMenuWithdrawValue);
  const dispatch = useDispatch();

  const handleChange = (event: SelectChangeEvent) => {
    console.log(dropdownMenuWithdrawValue);
    const count = (event.target.value as string).split(' ')[0];
    const name = (event.target.value as string).split(' ')[1];
    // const date = (event.target.value as string).split(' ')[2];
    const icon = menuItemsWithIcon.find(item => item.name === name && item.count === count)?.icon;
    
    dispatch(setDropdownMenuWithdrawValue({
      icon: icon,
      name: name,
      count: count,
      // date: date,
    }));

    if ((event.target.value as string).length > 0) {
      choice = true;
    } else {
      choice = false;
    }
  };

  const handleClose = () => {
    if (choice || dropdownMenuWithdrawValue?.name) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  };

  const handleError = () => isSelected;

  const errorMemo = React.useMemo(handleError, [isSelected]);

  const menuItems = menuItemsWithIcon.map((item) => 
    <MenuItem 
      key={item.name}
      value={`${item.count} ${item.name}`}
      sx={[
        {
          padding: '12px',
          color: '#212324',
          gap: '16px'
        },
        {
          '&:hover': {
            backgroundColor: '#DDE7F0',
          },
          '&:focus': {
            backgroundColor: '#C9DFF1',
          }
        },
      ]}
      >
        {item.icon}
        {`${item.count} ${item.name}`}
    </MenuItem>
  )

  React.useEffect(() => {
    dispatch(setDropdownMenuWithdrawValue(menuItemsWithIcon[0]));
  }, [menuItemsWithIcon]);

  return (
    <Box>
      <FormControl
        disabled={disabled}
        sx={[
        { m: 1, width: '95%' },
        {
          '&.MuiFormControl-root': {
            margin: 0,
          }
        },
        ]} 
        error={!errorMemo}>
        <InputLabel id="select-label" sx={[
          {
            fontSize: 16,
            lineHeight: '24px',
            color: '#616365',
          },
          {
            '&.Mui-focused': {
              fontWeight: '500',
              padding: '0 4px',
              marginLeft: '-3px',
            },
            '&.MuiInputLabel-shrink': {
              fontWeight: '500',
              marginLeft: '-3px',
              padding: '0 4px',
            },
            '&.Mui-disabled': {
              color: '#E4E5E5'
            },
          },
          
        ]}>
          {labelName}
          </InputLabel>
        <Select
          labelId="select-label"
          id="select-withdraw"
          value={`${dropdownMenuWithdrawValue.count} ${dropdownMenuWithdrawValue.name}`}
          defaultValue={""}
          label={labelName}
          onChange={handleChange}
          onClose={handleClose}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: '#EAF5FE',
              },
            },
          }}
          sx={[
            {
              color: '#212324',
            },
            {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#616365',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                backgroundColor: '#EAEAEA',
                borderColor: '#616365'
              },
              '&:focus .MuiOutlinedInput-notchedOutline': {
                borderColor: '#2196F3'
              },
              '.MuiSvgIcon-root': {
                zIndex: 1,
                fill: !errorMemo ? '#DC362E' : '#616365',
              },
              '&:focus .MuiSelect-icon': {
                fill: '#2196F3',
              },
              '.MuiSelect-iconOpen': {
                fill: '#2196F3',
              },
              '.MuiSelect-select': {
                zIndex: 1,
                display: 'flex',
                alignItems: 'center',
                gap: '13px',
              },
              '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                backgroundColor: '#F5F5F9',
                borderColor: '#DBDBDB'
              },
              '&.Mui-disabled .MuiSelect-icon': {
                fill: '#DBDBDB',
              },
            },
          ]}
        >
          {menuItems}
        </Select>
        {
          errorMemo ? null :
          <FormHelperText>Selection required</FormHelperText>
        }
      </FormControl>
    </Box>
  );
}

