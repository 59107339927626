import { Box, FormControl, FormHelperText, Icon, InputLabel, List, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { Coin } from '../../../data/Coins';
import { setDropdownMenuValue } from '../../../features/selects/dropdownMenuValueSlice';

export interface IDropdownMenuProps {
  labelName: string;
  menuItemsWithIcon: Array<Coin>;
  disabled?: boolean;
}

export function DropdownMenu({ labelName, menuItemsWithIcon = [], disabled = false }: IDropdownMenuProps) {
  const [isSelected, setIsSelected] = React.useState(true);
  let choice = false;
  const dropdownMenuValue: Coin = useSelector((state: RootState) => state.dropdownMenuValue);
  const dispatch = useDispatch();

  const handleChange = (event: SelectChangeEvent) => {
    const icon = menuItemsWithIcon.find(item => item.name === (event.target.value as string))?.icon;
    dispatch(setDropdownMenuValue({
      icon: icon,
      name: (event.target.value as string)
    }))
    if ((event.target.value as string).length > 0) {
      choice = true;
    } else {
      choice = false;
    }
  };

  const handleClose = () => {
    if (choice || dropdownMenuValue?.name) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  };

  const handleError = () => isSelected;

  const errorMemo = React.useMemo(handleError, [isSelected]);

  const menuItems = menuItemsWithIcon.map((item) => 
    <MenuItem 
      disabled={!item.isActive}
      key={item.name}
      value={item.name}
      sx={[
        {
          padding: '12px',
          color: '#212324',
          gap: '16px'
        },
        {
          '&:hover': {
            backgroundColor: '#DDE7F0',
          },
          '&:focus': {
            backgroundColor: '#C9DFF1',
          },
          '.Mui-disabled': {
            color: '#9EA0A2',
          },
        },
      ]}
      >
        <Icon sx={{
          width: '26x',
          height: '26px'
        }}>
          {item.icon}
        </Icon>
        {item.name}
    </MenuItem>
  )

  return (
    <Box>
      <FormControl
        disabled={disabled}
        sx={[
        { m: 1, width: '95%' },
        {
          '&.MuiFormControl-root': {
            margin: 0,
          }
        },
        ]} 
        error={!errorMemo}>
        <InputLabel id="select-label" sx={[
          {
            fontSize: 16,
            lineHeight: '24px',
            color: '#616365',
          },
          {
            '&.Mui-focused': {
              fontWeight: '500',
              padding: '0 4px',
              marginLeft: '-3px',
            },
            '&.MuiInputLabel-shrink': {
              fontWeight: '500',
              marginLeft: '-3px',
              padding: '0 4px',
            },
            '&.Mui-disabled': {
              color: '#E4E5E5'
            },
          },
          
        ]}>
          {labelName}
          </InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={dropdownMenuValue?.name}
          defaultValue={"Coin"}
          label={labelName}
          onChange={handleChange}
          onClose={handleClose}
          MenuProps={
            {
            PaperProps: {
              sx: {
                backgroundColor: '#EAF5FE',
                maxHeight: '160px',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px',
                }, 
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  border: '2px solid transparent',
                }
              },
            },
          }}
          sx={[
            {
              color: '#212324',
            },
            {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#616365',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                backgroundColor: '#EAEAEA',
                borderColor: '#616365'
              },
              '&:focus .MuiOutlinedInput-notchedOutline': {
                borderColor: '#2196F3'
              },
              '.MuiSvgIcon-root': {
                zIndex: 1,
                fill: !errorMemo ? '#DC362E' : '#616365',
              },
              '&:focus .MuiSelect-icon': {
                fill: '#2196F3',
              },
              '.MuiSelect-iconOpen': {
                fill: '#2196F3',
              },
              '.MuiSelect-select': {
                zIndex: 1,
                display: 'flex',
                alignItems: 'center',
                gap: '13px',
              },
              '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                backgroundColor: '#F5F5F9',
                borderColor: '#DBDBDB'
              },
              '&.Mui-disabled .MuiSelect-icon': {
                fill: '#DBDBDB',
              },
            },
          ]}
        >
          {menuItems}
        </Select>
        {
          errorMemo ? null :
          <FormHelperText>Selection required</FormHelperText>
        }
      </FormControl>
    </Box>
  );
}

