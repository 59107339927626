import { Button } from '@mui/material';
import React from 'react';

export interface IButtonProps {
  text: string;
  startIcon?: React.ReactNode;
  startIconDisabled?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void; 
}

export function FilledButton({ text, startIcon, startIconDisabled, disabled = false, onClick = () => {} }: IButtonProps) {
  return (
    <Button
      color="primary" 
      variant="contained"
      disabled={disabled}
      startIcon={!disabled ? startIcon : startIconDisabled} 
      disableRipple
      onClick={onClick}
      sx={[
        {
          padding: '10px 24px',
          borderRadius: '100px',
          boxShadow: 'none'
        },
        {
          '&:hover': {
            backgroundColor: '#4BA6EE',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'
          }
        },
        {
          '&:focus': {
            backgroundColor: '#53AEF5',
            boxShadow: 'none'
          }
        },
        {
          '&:active': {
            backgroundColor: '#53AEF5',
            boxShadow: 'none'
          }
        }
      ]}>
        {text}
    </Button>
  );
}
