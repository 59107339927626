import { Box } from '@mui/material';
import React from 'react';
import { contactsData } from '../../../data/ContactsData';
import { ContactLink } from '../ContactLink';

export function ContactUs() {
  return (
    <Box display={'flex'} alignItems={'center'} gap={'12px'}>
      {
        contactsData.map((contact) => 
          <ContactLink link={contact.link} icon={contact.icon} />
        )
      }
    </Box>
  );
}
