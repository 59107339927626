import { Typography } from '@mui/material';
import React from 'react';
import { ITextProps } from '../../ItextProps';

export function BodyLarge({ text, color }: ITextProps) {
  return (
    <Typography color={color} sx={[
      {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.5px',
      }
    ]}>
      {text}
    </Typography>
  );
}
