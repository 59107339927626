import { AMOUNT, DEAL_REVIEW, FEES_OPTIMIZATION, METAMASK_APPROVAL, UNDERLYING_ASSET } from "../states/States";

export const progressCardDepositData = {
  list: [
    UNDERLYING_ASSET,
    METAMASK_APPROVAL,
    AMOUNT,
    FEES_OPTIMIZATION,
    DEAL_REVIEW,
  ],
  listWithoutApproval: [
    {
      name: "Underlying asset",
      isActive: true,
    },
    AMOUNT,
    FEES_OPTIMIZATION,
    DEAL_REVIEW,
  ],
  listWithoutApprovalDisabled: [
    UNDERLYING_ASSET,
    AMOUNT,
    FEES_OPTIMIZATION,
    DEAL_REVIEW,
  ]
}