import { Box } from '@mui/material';
import React from 'react';
import { BodyLarge } from '../../text/body/BodyLarge';
import { BodyMedium } from '../../text/body/BodyMedium';

export interface INotificationProps {
  title?: string;
  descriptionText: Array<string>;
  childrenBefore?: React.ReactNode;
  childrenAfter?: React.ReactNode;
  disabled?: boolean;
}

export function Notification({ title, descriptionText, childrenBefore, childrenAfter, disabled = false }: INotificationProps) {
  return (
    <Box sx={[
      {
        backgroundColor: disabled ? '#EEEEF2' : '#ffffff',
        padding: '24px',
        filter: 'drop-shadow(0px 10px 40px rgba(225, 230, 235, 0.4))',
        borderRadius: '4px',
      }
    ]}>
      {
        childrenBefore ? 
        <Box>
          {childrenBefore}
        </Box>
        : null
      }
      {
        title ?
        <Box>
          <BodyLarge color={disabled ? '#9EA0A2' : '#212124'} text={title} />  
        </Box>
        : null
      }
      {
        descriptionText.map((text) =>
          <Box mt={2}>
            <BodyMedium color={disabled ? '#BDBFC1' : '#424246'} text={text} /> 
          </Box>
        )
      }
      {
        childrenAfter ?
        <Box mt={2} mb={1}>
          {childrenAfter}
        </Box>
        : null
      }
    </Box>
  );
}
